<ion-header>
    <ion-toolbar color="tertiary" class="mobile-visibility" [ngClass]="{'app-view-header': isApkWebView}">
        <div class="title-block title-block-custom">
            <div class="icon-title">
                <ion-icon name="arrow-back-outline" class="back-button" (click)="goBack()"></ion-icon>
                <ion-title class="title">Connected Accounts</ion-title>
            </div>
        </div>
    </ion-toolbar>
</ion-header>
<ion-content >
    <div class="container">
    <div class="connected-ac-main-skeleton" *ngIf="familyMappList.length == 0">
        <div class="top-header connected-ac-skeleton">
            <div class="header desktop-visibility">
              <p class="skeleton-line"></p>
            </div>
            <span class="skeleton-line ske-button"></span>
        </div>
    <div class="connected-ac-skeleton box-pannel mb-20">
        <div class="cl-name-with-refresh">
            <div class="user-name-card">
                <div class="short_name skeleton-square circle"></div>
                <div>
                    <span class="skeleton-line sk-name"></span>
                    <div class="d-flex client_id_select align-center">
                        <label class="skeleton-line"></label>
                        <span class="hierarchyType skeleton-line"></span>
                    </div>  
                </div>
            </div>
            <div>
                <span class="skeleton-line ske-button"></span>
            </div> 
        </div>
        <div class="total-main">
            <div class="total-value">
                <span class="skeleton-line"></span> <span class="skeleton-line"></span>  
            </div> 
            <div class="broker-grid-box">
                <div class="broker-grid">
                    <span class="skeleton-line"></span>
                    <label class="skeleton-line"></label>
               </div>
               <div class="broker-grid">
                <span class="skeleton-line"></span>
                <label class="skeleton-line"></label>
           </div>
           <div class="broker-grid">
            <span class="skeleton-line"></span>
            <label class="skeleton-line"></label>
       </div>
       <div class="broker-grid">
        <span class="skeleton-line"></span>
        <label class="skeleton-line"></label>
   </div>
            </div>
        </div>
    </div>
    <div class="connected-ac-skeleton box-pannel mb-20">
        <div class="cl-name-with-refresh">
            <div class="user-name-card">
                <div class="short_name skeleton-square circle"></div>
                <div>
                    <span class="skeleton-line sk-name"></span>
                    <div class="d-flex client_id_select align-center">
                        <label class="skeleton-line"></label>
                        <span class="hierarchyType skeleton-line"></span>
                    </div>  
                </div>
            </div>
            <div>
                <span class="skeleton-line ske-button"></span>
            </div> 
        </div>
    </div>
</div>
    <div *ngIf="familyMappList.length > 0">
        <div class="top-header">
            <div class="header desktop-visibility">
                <img (click)="goBack()" src="assets/svg/back-icon.svg" alt="img">
                <p>Connected Accounts</p>
            </div>
            <a *ngIf="isPartner == 'true'"
            href="javascript:void(0)" (click)="generateLink()"
                
                ><img src="assets/svg/generate-link-icon.svg" width="16px" alt="generate-link-icon"> Generate Link
            </a>
        </div>
        <div class="box-pannel mb-20" *ngFor="let item of familyMappList; let i as index">
            <div class="cl-name-with-refresh">
            <div class="user-name-card">
                <div class="short_name">{{getShortName(item?.ClientName)}}</div>
                <div>
                    <span class="clientNameCss">{{item?.ClientName}}</span>
                <div class="d-flex client_id_select align-center">
                    <label>{{item?.ClientCode}}</label>
                    <span class="hierarchyType">{{item?.Relation}}</span>
                </div>
                </div>
            </div>
            <!-- add condation in below dropdown -->
            <!-- 
            " -->
            <div class="status-btn cbmt-0"
            *ngIf="item?.consentData?.has_mf_consent == false && 
                        item?.consentData?.has_mf_portfolio== false && 
                        item?.consentData?.has_stock_consent == false && 
                        item?.consentData?.has_stock_portfolio == false 
                        "
            >
                <a href="javascript:void(0)"
                         (click)="generateExternalConnectLink(item.ClientCode,'mf')"
                        >Connect Now</a>
            </div>
            <div class="refresh-grid" 
            *ngIf="item?.consentData?.has_mf_consent == true ||
            item?.consentData?.has_mf_portfolio== true ||
            item?.consentData?.has_stock_consent == true || 
            item?.consentData?.has_stock_portfolio == true "
            >
                <div class="re-fe" (click)="showRefresh(i)">
                    <img src="assets/svg/refresh-icon.svg" width="16px" alt="refresh-icon">
                Refresh
                <img  class="down-arrow" src="assets/svg/arrow_next.svg" alt="down">
                </div>
                <div class="refresh-dropdown" *ngIf="showrefreshlist == i">
                 <div class="refresh-grid-list" (click)="refreshExternalStockData(selectedClient?.ClientCode,selectedClient?.Pan,selectedClient?.PartnerCode)">
                    <div>
                        <label>Stocks</label>
                    </div>
                    <img src="assets/svg/refresh-icon.svg" width="20px" alt="refresh-icon" >
                 </div>
                 <div class="refresh-grid-list" (click)="generateExternalConnectLink(selectedClient?.ClientCode,'')">
                    <div>
                        <label>Mutual Funds</label>
                    </div>
                    <img src="assets/svg/refresh-icon.svg" width="20px" alt="refresh-icon" >
                 </div>
                </div>
            </div>
        </div>
        <div class="total-main" *ngIf="item.TotalValue  > 0">
        <div class="total-value">
        Total Value : <span>₹ {{item.TotalValue | formatNumerDecimal}}</span>
        </div>
      

        <div class="broker-grid-box">
           <div class="broker-grid" *ngFor="let broker of item.externalBrokerList">
                <span>{{broker?.name?.toUpperCase()}}</span>
                <label>
                    ₹ {{broker?.total  | formatNumerDecimal}}
                </label>
           </div> 
        </div>
    </div>


        </div>
    </div>  
        

        <div class="add-account mb-20 add-member" *ngIf="familyMappList.length"
        (click)="addMemberForm()"><img src="assets/svg/add-member.svg" width="22px"  alt="plus">Add Family Member</div>

<div class="box-pannel text-center mb-20" style="display: none;">
    <img src="assets/svg/broker-circle.PNG" width="180px" alt="broker-circle">
    <h5>Connect Your</h5>
    <h2>Portfolios across broker a/cs</h2>
    <div class="list-deatails">
        <span>View all portfolios in one place</span>
        <span>Get in-depth analysis</span>
        <span>Enjoy daily tracking</span>
    </div>
    <div class="orange-btn">Get OTP</div>
    <p>You’ll be redirected to AAA portal</p>



</div>

        
    </div>

    <div class="generatelink-modal  modal-box text-center new-modal-box" *ngIf="hideModel">
		<a href="javascript:void(0)" class="close-btn" (click)="closegeneratelink()">
			<img src="assets/imgs/close_btn.svg" width="18px">
		</a>
		<div class="modal-title">Generated Link</div>
		<div class="generate-link-box">
				<span>{{generatedLink}}</span>
				<button class="copy-link" (click)="copyMessage(generatedLink)"><img src="assets/svg/copy-icon.svg" width="20px"></button>
		</div>
		<div class="link-msg">
			<div>
				<img src="assets/svg/fill-mark.svg">
				<span>Link successfully generated</span>
			</div>
			<!-- <div >
				<img src="assets/svg/re-generate-icon.svg">
				<a href="#">Regenerate</a>
			</div> -->
		</div>
		<!-- <div class="orange-btn">Send Link via Email</div>
		<a href="#">Share via WhatsApp</a> -->
</div>
<div class="addmemeberForm1  modal-box" *ngIf="AddmemOverlay">
	<div class="modal-head d-flex">
		<div class="modal-title">Add Family Member</div>
		<a href="javascript:void(0)" class="close_btn" (click)="closeForm()">
			<img src="assets/imgs/close_btn.svg"></a>
	</div>
	<div class="modal-body">
		<div class="modal-content">
			<div class="otp_section_main" id="memberPop">
				<h2>OTP sent to your family member’s registered
					mobile number.</h2>

				<form class="addmemberfrom_box" *ngIf="display1">
					<div class="form-group">
						<label for="clientid">Family Member’s Client ID</label>
						<input type="clientid" class="form-control" id="clientId" [(ngModel)]="memberClientCode"
							[ngModelOptions]="{standalone: true}">
					</div>
					<div class="form-group">
						<label for="Relation">Family Member’s Relation</label>
						<select class="form-control" id="Relation" [(ngModel)]="selectRelation"
							[ngModelOptions]="{standalone: true}">
							<option value="Brother">Brother</option>
							<option value="Son">Son</option>
							<option value="Father">Father</option>
							<option value="Mother">Mother</option>
							<option value="Spouse">Spouse</option>
							<option value="Other">Other</option>
						</select>
					</div>
					<button type="submit" id="memberBtn" class="btn btn-default orange-btn"
						(click)="addMemberFormnext()">Continue</button>
				</form>
				<form class="addmemberfrom_box verification-code" *ngIf="display2">
					<div class="verification_code_inputs d-flex">
						<code-input #codeInput [isCodeHidden]="false" [codeLength]="6" [code]="otpInput"
							[initialFocusField]="0" (codeChanged)="onOtpChanged($event)"
							(codeCompleted)="onotpFieldCompleted($event)">
						</code-input>
						<!-- <input type="number" id="text1" maxlength="1" />
					<input type="number" id="text2" maxlength="1" />
					<input type="number" id="text2" maxlength="1" />
					<input type="number" maxlength="1" />
					<input type="number" maxlength="1" />
					<input type="number" maxlength="1" /> -->
					</div>
					<span class="error d-flex">Please enter a valid OTP</span>
					<span class="otp_msg">Didn’t receive the OTP? Retry in <span> 00:59 secs</span></span>

					<button type="submit" class="btn btn-default orange-btn" [disabled]="verifyBtn"
						(click)="verifyMember()">Verify</button>
				</form>
			</div>
			<div class="add_member_successfully" *ngIf="display3">
				<img src="assets/imgs/acc-details/img/member_success.svg">
				<span>Your family member added successfully</span>
				<button type="submit" class="btn btn-default orange-btn" (click)="closeForm()">Done</button>
			</div>
		</div>
	</div>
</div>
<div  class="tableoverlay" *ngIf="hideModel"></div>
<div class="tableoverlay" *ngIf="AddmemOverlay"></div>

</ion-content>
