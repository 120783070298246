import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URLS } from "../../../../config/api.config";
import { CommonService } from "../../../helpers/common.service";
import { environment } from "../../../../environments/environment";
import { HTTP } from "@awesome-cordova-plugins/http/ngx";

@Injectable()
export class GuestDashBoardService {
	public options: any = environment['optionalHeaders'];
	private nativeHeaders: any = environment['nativeHeaders'];
	private gatewaySubscriptionKey = environment['gatewaySubscriptionKey'];

	private userOnboard = URLS.userOnboard;

	constructor(private httpClient: HttpClient,
		private nativeHttp: HTTP,
		private commonService: CommonService,
	) {
	}
	
	public getUserOnboardData(mob: any): Observable<{}> {
		let params = {
			"MobileNo": mob,
			"Key": this.userOnboard.key,
			"AppName": this.userOnboard.appName,
		}
		let obj: any = {};
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.userOnboard.url, params, Object.assign(obj, this.nativeHeaders, this.options))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.userOnboard.url, params, { headers: new HttpHeaders(obj) });
	}
}