import { Injectable } from "@angular/core";
import { URLS } from '../../../config/api.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { environment } from "../../../environments/environment";
import { CommonService } from "../../helpers/common.service";
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { StorageServiceAAA } from "../../helpers/aaa-storage.service";
import { CustomEncryption } from "../../../config/custom-encrypt";

interface ParamsBody {
	LoginId: string;
	Reason?: string;  // Optional property for Reason
	ClientCode?: string; 
	SearchBy?: string,
	Searchtext?: string,
	FromDate?:any,
	ToDate?:any // Optional property for ClientCode
	LoginID? : any
  }

@Injectable()
export class WireRequestService {



private limitReqStatus = URLS.limitReqStatus;
private jvReqStatus = URLS.jvReqStatus;
private epiReqStatus = URLS.epiReqStatus;
private clientCodes = URLS.clientCodesList; //no used in code
private limitRequest = URLS.limitInsert;
private limitValidate = URLS.limitValidate;
//private jvValidate = URLS.jvValidate; //not used in the code
private jvInsert = URLS.jvInsert;
private brokerageApproval = URLS.brokerageApprove;
private brokergeRequest = URLS.brokergeRequest;
private brokergeRequestV1 = URLS.brokergeRequestV1;
private shareDepositDetailsV1 = URLS.subBrokerShareDepositDetailsV1
private brokerageReqInsert = URLS.brokerageRequestInsertV1
private nfdcReport = URLS.nfdcRiskReport
private scriptExcel = URLS.scriptExcel
private investEdge = URLS.investEdge
private investcurrentTime = URLS.investcurrentTime
private optimum = URLS.optimum
private BodHoldingEq = URLS.BodHolding
private BodHeaderEq = URLS.BodHeader
private FnoPositions = URLS.FnoPositions
private SlbmHoldings = URLS.SlbmHoldings
private clientSummary = URLS.clientSummary
private getPhysicalFNOReports = URLS.getPhysicalFNOReports
private editClientSummary = URLS.editClientSummary
private BranchMapping  = URLS.BranchMapping
private profileDetails = URLS.profileDetailsV1
private ClientProfileCapture = URLS.clientProfileCap
private clientOutstandingReport = URLS.clientOutstanding
private ClientProfileScore = URLS.clientRiskProfileScore
private hierarchyList = URLS.hierarchyList
private brokCurrentDetails = URLS.hybridBrokRequest
private brokCurrentDetailsV1 = URLS.hybridBrokRequestV1
private holdPhysicalFNOReports=URLS.holdPhysicalFNOReports;
private scripSummaryReport = URLS.scripSummaryReport
private brokerageInfo = URLS.brokerageInformation
private clientInteractionToken = URLS.clientInteractionToken;
private clientTicketDetailsUrl = URLS.clientTicketDetails;
private clientInteractionDetailsUrl = URLS.clientInteractionDetails;
private commoSummary = URLS.commSummaryList;
private tradingListRepo = URLS.tradingListRepo;
private commodityScripSum = URLS.commodityScripSum;
private vasDetailedReport = URLS.vasDetailedReport;
private RaaDebit   = URLS.RaaDebit

private brokApprovalRej = URLS.brokApproRej
private productActDea = URLS.productActivationRights
private actDecProd = URLS.actDecProduct

private cmsDeposit = URLS.cmsDeposit;
private equityCms = URLS.equityCms;
private saveCmsEntry = URLS.saveCmsEntry;
private jvStatusOptions = URLS.jvStatusOption;
private getDPSchemeDetails=URLS.getDPSchemeDetails;
private ttMapping = URLS.ttMapping
private dpScriptReport = URLS.dpScript;
private getLivlongVal = URLS.getLivlong;
private getMarginShortfallVal = URLS.getMarginShortfall;
private clientMappingTableData = URLS.clientMappingTableData;
private getBeyongIRRVal = URLS.getBeyongIRR;
private freezeDetails = URLS.freezeDetails;
private dpModificationDetails = URLS.dpModificationDetails;
private searchScripDetails = URLS.searchScripDetails;
private clientDematHoldings = URLS.clientDematHoldings;
private SubmitEPIRequest = URLS.SubmitEPIRequest;
//private clientMarginReport = URLS.clientMarginReport; // not called
private accountClosureStatus = URLS.accountClosureStatus;
private settlementPayoutReport = URLS.settlementPayoutReport;
private dematRequestStatus = URLS.dematRequestStatus;
private mappedClient = URLS.mappedClient;
private demapClnt = URLS.demapClient;
private downloadDematForm = URLS.reportDownload;
private aumExcelReportDownload = URLS.AumExcelReportDownload;
private disInwardDetail = URLS.disInwardDetail;
private onlineSIPExcelReportDownload = URLS.OnlineSIPExcelReportDownload;
private GetJVDetails = URLS.GetJVDetails

public options: any = environment['optionalHeaders'];
public headersParams: any = environment['headersParams'];
public cookieKEYclientInteraction: any = environment['cookieKEYclientInteraction'];
private nativeHeaders: any = environment['nativeHeaders'];
private gatewaySubscriptionKey = environment['gatewaySubscriptionKey'];
private nativeHeadersClientInteraction: any = environment['nativeHeadersClientInteraction'];
private globalCheckSumkey = environment['globalChecksumKey']
public tokenVal: any;
public paramsObj: any;
	userType: any;

constructor(private httpClient: HttpClient,
	private nativeHttp: HTTP,
	private storage: StorageServiceAAA,
	private commonService: CommonService,private cipherText: CustomEncryption) {
		this.storage.get('userType').then(type => {
			if (type === 'RM') {
				this.userType = type;
				this.tokenFunction()
			} else if (type === 'FAN') {
				this.userType = 'FN';
				this.tokenFunction()
			} else {
				this.userType = 'SB';
			}
			
		})
		
	}
	tokenFunction(){
		this.storage.get('cookieValue').then(token => {
			this.tokenVal = token;
			let modifiedToken = this.tokenVal.split(";");
			let str = '';
			for(let i = 0 ; i < modifiedToken.length ; i++){
				if(modifiedToken[i].includes('ASP.NET_SessionId')){
					str = str + modifiedToken[i] + ';';
				}
				if(modifiedToken[i].includes('SameSite=Lax')){
					str = str + modifiedToken[i] + ';';
				}
				}
				this.tokenVal = str;
				this.tokenVal = this.tokenVal.replace(' SameSite=Lax','');
				this.tokenVal = this.tokenVal.replace('Path=/','');
		});
	}

    
    public getLimitReqStatus(cookievalue: any,caseId: any, passObj: any, userId: any): Observable<{}> {
		let params = {"body":{"CaseID":caseId,"CustomerID":"","EmployeeId":userId,"FromDate":passObj.fromDate,"Status":"","ToDate":passObj.toDate},"head":{"appname":this.limitReqStatus.appName,"appver":"1.0.26.0","Authkey":"53D7AE755B82D5C2","key":this.limitReqStatus.key,"osname":"Android","requestcode":"APIBO52UCVDWFY",
		"userType": localStorage.getItem('userType')}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.limitReqStatus.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.limitReqStatus.url, params, { headers: new HttpHeaders(Object.assign(obj))});
		
	}

	public getMappedClients(clientId: any, cookievalue: any): Observable<{}> {
		let params: any = {
			"head": {
				"RequestCode": "CVUpdateLead01",
				"Key": this.mappedClient.key,
				"AppName": this.mappedClient.appName,
				"AppVer": "01",
				"OsName": "Android"
			},
			"body": {
				"ClientCode": clientId
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.mappedClient.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.mappedClient.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public demapClient(dataToSend: any, token: any) {
		let params: any = {
			"head": {
				"RequestCode": "APIBO52UCVDWFY",
				"Key": this.demapClnt.key,
				"AppVer": "01",
				"AppName": this.demapClnt.appName,
				"OsName": "Android"
			},
			"body": dataToSend
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (token) {
			obj['token'] = token;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.demapClnt.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.demapClnt.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getClientMappingTableData(data: any, cookievalue: any): Observable<{}> {
		let params = {
			"body": data,
			"head": {
				"RequestCode": "APIBO52UCVDWFY",
				"Key": this.clientMappingTableData.key,
				"AppVer": "01",
				"AppName": this.clientMappingTableData.appName,
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.clientMappingTableData.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.clientMappingTableData.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getDpScript(cookievalue: any, passObj: any, userId: any): Observable<{}> {
		let params = {"body":{"FromDate":passObj.fromDate,"PartnerCode":userId,"ToDate":passObj.toDate},"head":{
			"AppName":this.dpScriptReport.appName,"AppVer":"01",
			"Key":this.dpScriptReport.key,"OsName":"Android","RequestCode":"CVUpdateLead01",
		"userType": localStorage.getItem('userType')}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}

		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.dpScriptReport.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.dpScriptReport.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}


	public getjvReqStatus(cookievalue: any, passObj: any, userId: any): Observable<{}> {
		let params = {"body":{"FromDate":passObj.fromDate,
		"RMCode":userId,
		"Remark":"",
		"Status":passObj.Status,
		"ToDate":passObj.toDate
	},"head":
	{
	"AppName":this.jvReqStatus.appName,
	"AppVer":"1.0.26.0",
	"Key":this.jvReqStatus.key,
	"OsName":"Android","RequestCode":"CVUpdateLead01",
	"userType": localStorage.getItem('userType')
    }
	}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}

		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.jvReqStatus.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.jvReqStatus.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getepiReqStatus(cookievalue: any, passObj: any, userId: any): Observable<{}> {
		let params = { 
			"head":{
				"AppName": this.epiReqStatus.appName,
				"AppVer": "1.0.4.0",
				"Key": this.epiReqStatus.key,
				"OsName": "Android",
				"RequestCode": "ProfileDetailsV1",
				"userType": localStorage.getItem('userType') },
			"body":{
				"FromDate": passObj.fromDate,
				"Partnercode": userId,
				"ClientCode":passObj.clinetCode,
				"ToDate": passObj.toDate,
				"Status": passObj.Status
			}
		};

		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}

		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.epiReqStatus.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.epiReqStatus.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public limitedValidator(cookievalue: any, pramsObj: any, userId: any): Observable<{}> {
		let params = {"body":{"EmployeeId":userId,"LoginID":pramsObj.clientID,"SegmentID":pramsObj.SegmentID},"head":{"AppName":this.limitValidate.appName,"AppVer":"1.0.26.0","Key":this.limitValidate.key,"OsName":"Android","RequestCode":"CVUpdateLead01",
		"userType": localStorage.getItem('userType')}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}		
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.limitValidate.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.limitValidate.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public limitedInsert(cookievalue: any, parameter: any, caseId: any, userId: any): Observable<{}> {
		let params = {"body":{"ActualLimit":parameter.ActualLimit,"CaseID":caseId,"CustomerID":parameter.clientID,"EmployeeId":userId,"MarginRequirement":parameter.MarginRequirement,"ReqLimit":parameter.ReqLimit,"ReqRemark":parameter.ReqRemark,"SegmentID":parameter.SegmentID,"Status":"P"},"head":{"AppName":this.limitRequest.appName,"AppVer":"1.0.26.0","Key":this.limitRequest.key,"OsName":"Android","RequestCode":"CVUpdateLead01",
		"userType": localStorage.getItem('userType')}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};		
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.limitRequest.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.limitRequest.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	

	

	public getBrokerageApproval(cookievalue: any, userID: any): Observable<{}> {
		let params = {"body":{"PartnerCode":userID},"head":{"AppName":this.brokerageApproval.appName,"AppVer":"1.0.26.0","Key":this.brokerageApproval.key,"OsName":"Android","RequestCode":"CVUpdateLead01",
		"userType": localStorage.getItem('userType')}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.brokerageApproval.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.brokerageApproval.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getBrokerageRequest(cookievalue: any, paramsObj: any, userID: any): Observable<{}> {
		let checkSumKey = this.cipherText.generateCheckSumKey();
		let fromDate = paramsObj.fromDate;
		let toDate = paramsObj.ToDate;
		let status = paramsObj.status;
		let rmID = this.cipherText.createChecksum(userID ,checkSumKey);
		let clientID = "";	
		let srNO = 0;
		let stringValue = `${userID}_${fromDate}_${toDate}_${clientID}_${srNO}_${status}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`;		
		const checkSumValue = this.cipherText.createChecksum(stringValue,this.globalCheckSumkey);
		

		let params = {
			"body":{
				"ClientCode":clientID,
				"FromDate":paramsObj.fromDate,
				"RMCode":rmID,
				"SrNo":srNO,
				"Status":paramsObj.status,
				"ToDate":paramsObj.ToDate},
				"head":{
					"AppName":this.brokergeRequestV1.appName,
					"AppVer":"1.0.26.0","Key":this.brokergeRequestV1.key,
					"OsName":"Android",
					"RequestCode":"CVUpdateLead01",
					"userType": localStorage.getItem('userType'),
					"CheckSum": checkSumValue,
					"CheckSumKey":checkSumKey
					}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		
		if (cookievalue) {    
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.brokergeRequestV1.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.brokergeRequestV1.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getShareDepositRecord(cookievalue: any, userID: any): Observable<{}> {
		let params = {"body":{"PartnerCode":userID},"head":{"appname":this.shareDepositDetailsV1.appName,"appver":"1.0.26.0","Authkey":"53D7AE755B82D5C2","key":this.shareDepositDetailsV1.key,"osname":"Android","requestcode":"APIBO52UCVDWFY",
		"userType": localStorage.getItem('userType')}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.shareDepositDetailsV1.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.shareDepositDetailsV1.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}
	//brokerage insert get current data
	public getBrokerageCurrentData(cookievalue: any, clientId: any): Observable<{}> {
			let checkSumKey = this.cipherText.generateCheckSumKey();
			let stringValue = `${clientId}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`;
			const checkSumValue = this.cipherText.createChecksum(stringValue,this.globalCheckSumkey);
			const clientID = this.cipherText.createChecksum(clientId ,checkSumKey);

		let params = {
			"head": {
					"RequestCode": "HybridBrokerageRequest",
					"Key": this.brokCurrentDetailsV1.key ,
					"AppName": this.brokCurrentDetailsV1.appName,
					"AppVer": "01",
					"OsName": "Android",
					"userType": localStorage.getItem('userType'),
					"CheckSum": checkSumValue,
					"CheckSumKey":checkSumKey
			},
			"body": {
				"ClientCode": clientID
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.brokCurrentDetailsV1.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.brokCurrentDetailsV1.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}
	// brokerge Insert
	// public getBrokerageInsert(cookievalue, passObj): Observable<{}> {
	// 	let params = {"body":{"CashDeliveryFirstSidePerc":passObj.cashDeliveryFirstSide,"CashDeliveryMinimumPerShare":passObj.cashDeliveryMps,"CashIntradayFirstSidePerc":passObj.cashIntradayFirstSide,"CashIntradayMinimumPerShare":passObj.cashIntradayMps,"CashIntradaySecondSidePerc":passObj.cashIntradaySecondSide,"CashT2TFirstSidePerc":passObj.cashT2TFirstSide,
	// 	"CashT2TMinimumPerShare":passObj.cashT2TMps,"ClientCode":passObj.clientID,"FuturesDeliveryFirstSidePerc":passObj.futureDeliveryFirstSide,"FuturesDeliveryMinimumPerShare":passObj.futureDeliveryMps,"FuturesIntradayFirstSidePerc":passObj.futureIntradayFirstSide,"FuturesIntradayMinimumPerShare":passObj.futureIntradayMps,
	// 	"FuturesIntradaySecondSidePerc":passObj.futureIntradaySecondSide,"hdnMTOF":"AAA","OptionsIndexPerc":passObj.optionIndex,"OptionsPerLot":passObj.optionPerLot,"RMCode":"ckv000rm","Remark":"AAA"},"head":{"AppName":this.brokerageReqInsert.appName,"AppVer":"1.0.26.0","Key":this.brokerageReqInsert.key,
	// 	"OsName":"Android","RequestCode":"CVUpdateLead01"}}
	// 	let obj = {};
	// 	if (cookievalue) {
	// 		obj = {
	// 			'token': cookievalue
	// 		}
	// 	}
	// 	if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
	// 	return this.commonService.isApp() ? from(this.nativeHttp.post(this.brokerageReqInsert.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
	// 		return JSON.parse(response['data'] as any);
	// 	})): this.httpClient.post(this.brokerageReqInsert.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	// }

	public getBrokerageInsert(cookievalue: any, passObj: any, userId: any): Observable<{}> {
		const valuesToCompare = [
			passObj.niftyOptionPerLot,
			passObj.bankniftyOptionPerLot,
			passObj.finniftyOptionPerLot,
			passObj.midcpniftyOptionPerLot,
			passObj.niftynxt50OptionPerLot,
			passObj.sensexOptionPerLot,
			passObj.bankexOptionPerLot,
			passObj.sensex50OptionPerLot,
		  ];
		  
		  // Check if all values are the same
		  const allAreEqual = valuesToCompare.every(value => value === valuesToCompare[0]);
		  
		  // Set the IndexOptions value based on the check
		  const indexOptionsValue = allAreEqual ? valuesToCompare[0] : 0;
		let params = {
			"head": {
					"RequestCode": "ActiveDeactiveProduct",
					"Key": this.brokerageReqInsert.key,
					"AppName": this.brokerageReqInsert.appName,
					"AppVer": "01",
					"OsName": "Android",
					"userType": localStorage.getItem('userType')
			},
			"body":{
				"CashDeliveryFirstSidePerc":passObj.cashDeliveryFirstSide,
				"CashDeliveryMinimumPerShare":passObj.cashDeliveryMps,
				"CashIntradayFirstSidePerc":passObj.cashIntradayFirstSide,
				"CashIntradayMinimumPerShare":passObj.cashIntradayMps,
				"CashIntradaySecondSidePerc":passObj.cashIntradaySecondSide,
				"CashT2TFirstSidePerc":passObj.cashT2TFirstSide,
				"CashT2TMinimumPerShare":passObj.cashT2TMps,
				"ClientCode":passObj.clientID,
				"FuturesDeliveryFirstSidePerc":passObj.futureDeliveryFirstSide,
				"FuturesDeliveryMinimumPerShare":passObj.futureDeliveryMps,
				"FuturesIntradayFirstSidePerc":passObj.futureIntradayFirstSide,
				"FuturesIntradayMinimumPerShare":passObj.futureIntradayMps,
				"FuturesIntradaySecondSidePerc":passObj.futureIntradaySecondSide,
				"hdnMTOF":"AAA",
				"OptionsIndexPerc":passObj.optionIndex,
				"OptionsPerLot":passObj.optionPerLot,
				"RMCode":userId,
				"Remark":"AAA",
				"RequestType":localStorage.getItem('brokTypeParams'),
				"CashOrdFlag":passObj.CashOrdFlag,
				"FaoOrdFlag":passObj.FaoOrdFlag,
				"BrkOrdwise":passObj.BrkOrdwise,
				"IndexOptions": indexOptionsValue,
				"NIFTY": passObj.niftyOptionPerLot,
				"BANKNIFTY": passObj.bankniftyOptionPerLot,
				"FINNIFTY": passObj.finniftyOptionPerLot,
				"MIDCPNIFTY":passObj.midcpniftyOptionPerLot,
				"NIFTYNXT50": passObj.niftynxt50OptionPerLot,
				"SENSEX": passObj.sensexOptionPerLot,
				"BANKEX": passObj.bankexOptionPerLot,
				"SENSEX50": passObj.sensex50OptionPerLot,
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.brokerageReqInsert.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.brokerageReqInsert.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getNfdcReport(cookievalue: any, passObj: any,userId: any): Observable<{}> {
		let params = {"body":{"ClientCode":"","Email":passObj.Email,"RMCode":localStorage.getItem('userId1'),"ReportType":passObj.reportType},"head":{"requestCode":passObj.requestCode,"key":this.nfdcReport.key,"appVer":"1.0.26.0","appName":this.nfdcReport.appName,"LoginId":passObj.encrpLogInId,
		"userType": localStorage.getItem('userType')}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.nfdcReport.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.nfdcReport.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getBodHoling(cookievalue: any, clientID: any, userID: any): Observable<{}> {
		let params = {"head": {"RequestCode": "BODHOLDING","Key": this.BodHoldingEq.key,"AppName": this.BodHoldingEq.appName,"AppVer": "1.0.4.0","OsName":"Android",
		"userType": localStorage.getItem('userType')},"body": {"PartnerCode": userID,"ClientCode": clientID}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.BodHoldingEq.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.BodHoldingEq.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getFnoPositions(cookievalue: any, clientID: any, userID: any): Observable<{}> {
		let params = {"head": {"RequestCode": "FNOPositions","Key": this.FnoPositions.key,"AppName": this.FnoPositions.appName,"AppVer": "1.0.4.0","OsName":"Android",
		"userType": localStorage.getItem('userType')},"body": {"PartnerCode": userID,"ClientCode": clientID}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.FnoPositions.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.FnoPositions.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getHoldingDetails(cookievalue: any, clientID: any, userID: any, holdingType: any): Observable<{}> {
		let params = {"head": {"RequestCode": "CVUpdateLead01","Key": this.BodHoldingEq.key,"AppName": "AAA","AppVer": "01","OsName":"Android",
		"userType": localStorage.getItem('userType')},"body": {"PartnerCode": userID,"ClientCode": clientID,"HoldingType": holdingType}}
		let obj: any = {
			'appID': localStorage.getItem('appID') || '',
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.BodHoldingEq.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.BodHoldingEq.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getHeaderDetails(cookievalue: any, clientID: any): Observable<{}> {
		let params = {"head": {"RequestCode": "CVUpdateLead01","Key": this.BodHeaderEq.key,"AppName":this.BodHeaderEq.appName,"AppVer": "01","OsName":"Android",
		"userType": localStorage.getItem('userType')},"body": {"ClientCode": clientID}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.BodHeaderEq.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.BodHeaderEq.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getSlbmHoldings(cookievalue: any, clientID: any, userId: any): Observable<{}> {
		let params = {"head": {"RequestCode": "SLBMHoldings","Key": this.SlbmHoldings.key,"AppName": this.SlbmHoldings.appName,"AppVer": "1.0.4.0","OsName": "Android",
		"userType": localStorage.getItem('userType')},"body": {"PartnerCode": userId,"ClientCode": clientID}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.SlbmHoldings.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.SlbmHoldings.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getClientSummary(cookievalue: any, branchId: any, SearchBy?: any, SearchText?: any, PageNo?: any,SortBy?: any,SortOrder?: any): Observable<{}> {
		let params = {"head": {"RequestCode": "CVUpdateLead01",
			"Key": this.clientSummary.key,
			"AppName": this.SlbmHoldings.appName,
			"AppVer": "01",
			"OsName": "Android",
		"userType": localStorage.getItem('userType')},"body": {"PartnerCode": localStorage.getItem('userId1'),"Category": "All", "Branch": branchId?branchId:"ALL", "PageNo": PageNo,
        "SortBy": SortBy?SortBy:"","SortOrder": SortOrder?SortOrder:"","SearchBy": SearchBy?SearchBy:"","SearchText": SearchText?SearchText:""
		}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.clientSummary.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.clientSummary.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public editSummary(cookievalue: any, passObj: any): Observable<{}> {
		let params = {"head": { "RequestCode": "DashboardDetail", "Key": this.editClientSummary.key, "AppName": this.editClientSummary.appName,"AppVer": "1.0.4.0", "OsName": "Android",
		"userType": localStorage.getItem('userType')}, "body": {"LoginId": passObj.LoginId,"HoldBlockSell": passObj.HoldBlockSell , "BranchRemarkID":passObj.BranchRemarkID, "MakerId": "C122883"}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.editClientSummary.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.editClientSummary.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getBranch(cookievalue: any, userId: any): Observable<{}> {
		let params = {"body": {"RmCode": userId},"head": {"RequestCode": "CVUpdateLead01","Key": this.BranchMapping.key,"AppName": this.BranchMapping.appName,"AppVer": "01","OsName": "Android",
		"userType": localStorage.getItem('userType')}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.BranchMapping.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.BranchMapping.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getHierarchyList(cookievalue: any, clientID: any): Observable<{}> {
		let params = {
			head: {
				RequestCode: "CVUpdateLead01",
				Key: this.hierarchyList.key,
				AppName: this.hierarchyList.appName,
				AppVer: "1.0.4.0",
				OsName: "Android",
			
			},
			body: {
				Loginid: clientID
			}
		}
		let obj: any = {
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.hierarchyList.url, params, Object.assign(obj, this.nativeHeaders, this.options))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.hierarchyList.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getProfileDetails(cookievalue: any, passObj: any): Observable<{}> {
		passObj.RequestorCode = localStorage.getItem('userId1');
		passObj.RequestorCode = localStorage.getItem('userId1') ? localStorage.getItem('userId1') : "";
		let params = { "head": { "RequestCode": "ProfileDetailsV1", "Key": this.profileDetails.key, "AppName": this.profileDetails.appName, "AppVer": "1.0.4.0", "OsName": "Android",
		"userType": localStorage.getItem('userType') }, "body": passObj }
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.profileDetails.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.profileDetails.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}
	public getclientDematHoldings(cookievalue: any, clientId: any, dematID: any, ISIN: any): Observable<{}> {
		let params = { 
			"head": { 
				"RequestCode": "CVUpdateLead01",
				 "Key": this.clientDematHoldings.key,
				  "AppName": this.clientDematHoldings.appName,
				   "AppVer": "01", 
				   "OsName": "Android",
				   "userType": localStorage.getItem('userType')
				},
			"body": {
				"Clientcode": clientId,
				"DematID": dematID,
				"ISIN": ISIN
			} 
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.clientDematHoldings.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.clientDematHoldings.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public submitEPIRequest(cookievalue: any, clientId: any, dematID: any, ISIN: any, symbol: any, qty: any): Observable<{}> {
		let params = { 
			"head": { 
				"RequestCode": "ProfileDetailsV1",
				 "Key": this.SubmitEPIRequest.key,
				  "AppName":this.SubmitEPIRequest.appName,
				   "AppVer": "1.0.4.0", 
				   "OsName": "Android",
				   "userType": localStorage.getItem('userType') 
				},
			"body": {
				"ParentCode": localStorage.getItem('userId1'),
				"ChildCode": clientId,
				"DematId": dematID,
				"ISIN": ISIN,
				"Symbol": symbol,
				"Qty": qty
			} 
			}
		
		let obj: any = {
		'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
		'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.SubmitEPIRequest.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.SubmitEPIRequest.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getProfileCap(cookievalue: any,userID: any,clientId: any): Observable<{}> {
		let params =  {
			"head": {
			"RequestCode": "clientProfileCap",
			"Key": this.ClientProfileCapture.key,
			"AppName": this.ClientProfileCapture.appName,
			"AppVer": "01",
			"OsName": "Android",
			"userType": localStorage.getItem('userType')},
			"body":{
				"loginid": userID,
				  "clientcode": clientId
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.ClientProfileCapture.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})):this.httpClient.post(this.ClientProfileCapture.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}
	public getOutstandingReport(cookievalue: any,fromDate: any,hierarchyListVar: any,N: any,U: any): Observable<{}> {
		let params =  {
			"head": {
			"RequestCode": "APIBO52UCVDWFY",
			"Key": this.clientOutstandingReport.key,
			"AppName": this.clientOutstandingReport.appName,
			"AppVer": "01",
			"OsName": "Android",
			"userType": localStorage.getItem('userType')},
			"body":{
				"LoginId": localStorage.getItem('userId1'),
				"Branch": hierarchyListVar,
				"AsOnDate": fromDate,
				"Exchange": N,
				"ExchangeType": U
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.clientOutstandingReport.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})):this.httpClient.post(this.clientOutstandingReport.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}
		
	public getProfileScore(cookievalue: any,userID: any,clientId: any,riskScore: any,riskCategory: any,aumCategory: any): Observable<{}> {
		let params =  {
			
			"body":{
				"LoginId": userID,
				"ClientId": clientId,
				"RiskScore":riskScore,
				"RiskCategory":riskCategory,
				"AUMCategory" : aumCategory
			},
			"head": {
				"RequestCode": "APIBO52UCVDWFY",
				"Key": this.ClientProfileScore.key,
				"AppVer": "01",
				"AppName":  this.ClientProfileScore.appName,
				"OsName": "Android",
				"userType": localStorage.getItem('userType')}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.ClientProfileScore.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})):this.httpClient.post(this.ClientProfileScore.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}
	
	public getBrokerageInfo(cookievalue: any, clientId: any): Observable<{}> {
		let params =  {"head": {"RequestCode": "CVUpdateLead01","Key": this.brokerageInfo.key,"AppName": this.brokerageInfo.appName,"AppVer": "1.0.4.0","OsName": "Android",
		"userType": localStorage.getItem('userType')},"body": {"ClientCode": clientId,"Segment": "ALL"}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.brokerageInfo.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.brokerageInfo.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}


	public getRaaDebitReport(cookievalue: any, reportType: any, userID: any) {
		let params =  {"head": {"requestCode": "RAADebitReport","key": this.RaaDebit.key,"appVer": "2.0","appName": this.RaaDebit.appName,"LoginId": reportType.encrpLogInId,
		"userType": localStorage.getItem('userType')},"body": {"RMCode": localStorage.getItem('userId1'),"ReportType":reportType.ReportType,"ClientCode":"","Email":"N"}}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.RaaDebit.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.RaaDebit.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getBrokApprovalRej(cookievalue: any, brokObj: any,reqRa: any, userId: any) {
		let params = { 
			"head": {
				"RequestCode": "CVUpdateLead01",
				"Key": this.brokApprovalRej.key,
				"AppName": this.brokApprovalRej.appName,
				"AppVer": "1.0.4.0",
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			},
			"body": {
			"RMCode": userId,
				"ClientCode": brokObj.ClientCode,
				"Remark": brokObj.Remark,
				"SerialNo": brokObj.Srno,
				"Status": reqRa
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.brokApprovalRej.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.brokApprovalRej.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	// public accessProductActivation(cookievalue,userId) {
	// 	let params = { 
	// 		"head": {
	// 			"RequestCode": " ActiveDeactiveRightsBase",
	// 			"Key": this.productActDea.key,
	// 			"AppName": "AAA",
	// 			"AppVer": "01",
	// 			"OsName": "Android"
	// 		},
	// 		"body": {
	// 			//"PartnerCode": "C86730"
	// 			"PartnerCode": userId
	// 		}
	// 	}
	// 	let obj = {};
	// 	if (cookievalue) {
	// 		obj = {
	// 			'token': cookievalue
	// 		}
	// 	}
	// 	if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
	// 	return this.commonService.isApp() ? from(this.nativeHttp.post(this.productActDea.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
	// 		return JSON.parse(response['data'] as any);
	// 	})): this.httpClient.post(this.productActDea.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	// }

	public submitProdActDec(cookievalue: any,passObj: any, userId: any) {
		let params = {
			"head": {
					"RequestCode": "ActiveDeactiveProduct",
					"Key": this.actDecProd.key,
					"AppName": this.actDecProd.appName,
					"AppVer": "01",
					"OsName": "Android",
					"userType": localStorage.getItem('userType')
			},
			"body": {
				"PartnerCode": userId,
				"ProductName":passObj.productName,
				"Action": passObj.action,
				"ClientCode": passObj.clientID,
				"Remark": passObj.remark,
				"IsFileUpload":passObj.isupload,
				"FileData":passObj.fileData
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.actDecProd.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})):  this.httpClient.post(this.actDecProd.url, params, { headers: new HttpHeaders(Object.assign(obj))});
	}

	//CMS Entry

	getCmsDepositBankList(cookievalue: any, user: any, typeNo: any){
		let params = {
		  "head":{
			 "RequestCode":"GetCMSClearingTypeV1",
			 "Key":this.cmsDeposit.key,
			 "AppName":this.cmsDeposit.appName,
			 "AppVer":"01",
			 "OsName":"Android",
			 "userType": localStorage.getItem('userType')
		  },
		  "body":{
			 "UserCode":user,
			 "ProductType":"EQ",
			 "UserType":typeNo
		  }
	   }
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.cmsDeposit.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.cmsDeposit.url,params,{ headers: new HttpHeaders(Object.assign(obj))});
		 
	  }

	  getEquityCms(cookievalue: any, clientId: any, userId: any, userType: any){
		let params = {
			"head": {
			  "RequestCode": "GetCMSClearingTypeV1",
			  "Key": this.equityCms.key,
			  "AppName": this.equityCms.appName,
			  "AppVer": "01",
			  "OsName": "Android",
			  "userType": localStorage.getItem('userType')
			},
			"body": {
			  "ProductType": "EQ",
			  "ClientID": clientId,
			  "UserID": userId,
			  "UserType": userType == "RM" ? "1" : userType == "FAN" ? "2" : "3",
			}
		  }

		  let obj: any = {
		  	'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			  'appID': localStorage.getItem('appID') || ''
		  };	
		  if (cookievalue) {
			obj['token'] = cookievalue;
		  }
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.equityCms.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.equityCms.url,params,{ headers: new HttpHeaders(Object.assign(obj))});
		 
	  }

	  saveEquityCms(cookievalue: any, passObj: any){
		let params = {
			"head": {
			  "RequestCode": "SaveCMSEntryV1",
			  "Key": this.saveCmsEntry.key,
			  "AppName": this.saveCmsEntry.appName,
			  "AppVer": "01",
			  "OsName": "Android",
			  "userType": localStorage.getItem('userType')
			},
			"body": passObj
		  }

		  let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		  };
		
		  if (cookievalue) {
			obj['token'] = cookievalue;
		  }
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.saveCmsEntry.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.saveCmsEntry.url,params,{ headers: new HttpHeaders(Object.assign(obj))});
		 
	  }

	  public editSummaryReport(cookievalue: any, HoldBlockSellandBranchRemarkIDList: any): Observable<{}> {
		let params = {"head": { "RequestCode": "CVUpdateLead01", "Key": this.editClientSummary.key, "AppName": this.editClientSummary.appName,"AppVer": "01", "OsName": "Android",
		"userType": localStorage.getItem('userType')}, "body": {"LoginId": localStorage.getItem('userId1'),HoldBlockSellandBranchRemarkIDList}}
		let obj: any = {
			'appID': localStorage.getItem('appID') || '',
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa
		};
		if (cookievalue) {
			obj['token'] = cookievalue	
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.editClientSummary.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.editClientSummary.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	//Done By Vaitheeswaran
	getallClientWireRequest(body: { PartnerCode: Promise<any>; ClientCode: string; },token: any) {
		let params={
			"head": {
				"RequestCode": "CVUpdateLead01",
				"Key": this.getPhysicalFNOReports.key,
				"AppName": this.getPhysicalFNOReports.appName,
				"AppVer": "01",
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			},
			"body": {
				"PartnerCode": body.PartnerCode,
				"ClientCode": body.ClientCode,
				"SearchBy": body.ClientCode?body.ClientCode:""
			}
		}
		let obj: any = {
			'appID': localStorage.getItem('appID') || '',
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa
		};
		if (token) {
			obj['token'] = token;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.getPhysicalFNOReports.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.getPhysicalFNOReports.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}
	  
	holdSelectedReports(groupList: any,token: any,userid: any) {
		
		let params={
			"head": {
				"Key": this.holdPhysicalFNOReports.key,
				"AppName": this.holdPhysicalFNOReports.appName,
			},
			"body": {
				"PartnerCode": userid,
				"data":groupList
			}
			}

		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (token) {
			obj['token'] = token;
		}
		
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.holdPhysicalFNOReports.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.holdPhysicalFNOReports.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	getallClientSearchWireRequest(body: { PartnerCode: any; ClientCode: any; }, tokendata: any) {
		let params={
			"head": {
				"RequestCode": "CVUpdateLead01",
				"Key": this.getPhysicalFNOReports.key,
				"AppName": this.getPhysicalFNOReports.appName,
				"AppVer": "01",
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			},
			"body": {
				"PartnerCode": body.PartnerCode,
				"ClientCode": body.ClientCode,
				"SearchBy": body.ClientCode?body.ClientCode:""
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (tokendata) {
			obj['token'] = tokendata;
		}
		
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.getPhysicalFNOReports.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.getPhysicalFNOReports.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
		}

		getDPSchemeCharges(clientCode: any,Token: any,userID: any) {
		
			let params= {
				"head": {
					"RequestCode": "CVUpdateLead01",
					"Key": this.getDPSchemeDetails.key,
					"AppName": this.getDPSchemeDetails.appName,
					"AppVer": "01",
					"OsName": "Android",
					"userType": localStorage.getItem('userType')
				},
				"body": {
				"PartnerCode": userID,
				"ClientCode": clientCode
				}
			}
			let obj: any = {
				'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
				'appID': localStorage.getItem('appID') || ''
			};
			if (Token) {
				obj['token'] = Token;
			}
		
			if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');

			return this.commonService.isApp() ? from(this.nativeHttp.post(this.getDPSchemeDetails.url, params,Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
				return JSON.parse(response['data'] as any);
			})): this.httpClient.post(this.getDPSchemeDetails.url,params,{ headers: new HttpHeaders(Object.assign(obj))});
		}
		
		public ttnowMapping(cookievalue: any, ttMappingData: any): Observable<{}> {
		let params = {"head": {"RequestCode": "CVUpdateLead01",
		"Key": this.ttMapping.key,
		"AppName": this.ttMapping.appName,
		"AppVer": "01",
		"OsName": "Android",
		"userType": localStorage.getItem('userType')}, "body": ttMappingData}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		obj['token'] = cookievalue;
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.ttMapping.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.ttMapping.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getClientInteractionToken(): Observable<{}> {
		let params: any = {};
		let passheader: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.iiflcrm
		};

		this.cookieKEYclientInteraction['Authorization'] = environment['cookieKEYclientInteractionToken'];
		this.nativeHeadersClientInteraction['Authorization'] = environment['cookieKEYclientInteractionToken'];
		
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.clientInteractionToken.url, params, Object.assign(passheader, this.nativeHeadersClientInteraction))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.clientInteractionToken.url, params, { headers: new HttpHeaders(Object.assign(passheader, this.cookieKEYclientInteraction)) });
	}

	public clientTicketDetails(cookievalue: any, ticketData: any): Observable<{}> {
		let params = {
			"Parameters": {
				"ClientCode": ticketData
			}
		};
		let obj = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.iiflcrm
		};
		this.cookieKEYclientInteraction['Authorization'] = 'CRM-oauthtoken ' + cookievalue;

		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.clientTicketDetailsUrl.url, params, Object.assign(obj, this.nativeHeadersClientInteraction))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.clientTicketDetailsUrl.url, params, { headers: new HttpHeaders(Object.assign(obj, this.cookieKEYclientInteraction)) });
	}

	public clientInteractionDetails(cookievalue: any, ticketData: any): Observable<{}> {
		let params = {
			"Parameters": {
				"ClientCode": ticketData
			}
		};
			let obj = {
				'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.iiflcrm
			};
			this.cookieKEYclientInteraction['Authorization'] = 'CRM-oauthtoken ' + cookievalue;
			
			if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
			return this.commonService.isApp() ? from(this.nativeHttp.post(this.clientInteractionDetailsUrl.url, params, Object.assign(obj, this.nativeHeadersClientInteraction))).pipe(map(response => {
				return JSON.parse(response['data'] as any);
			})): this.httpClient.post(this.clientInteractionDetailsUrl.url, params, { headers: new HttpHeaders(Object.assign(obj, this.cookieKEYclientInteraction)) });
		}

		public scriptMasterExcel(cookievalue: any, txt: any, filter: any): Observable<{}> {
			let params = {
				"body": {
					"login": localStorage.getItem('userId1'),    
					"txt": txt,
					"Filter": filter
				},
				"head": {
					"RequestCode": "CVUpdateLead01",
					"Key": this.scriptExcel.key,
					"AppName": this.scriptExcel.appName,
					"AppVer": "01",
					"OsName": "Android",
					"userType": localStorage.getItem('userType')
				}}
			let obj: any = {
				'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
				'appID': localStorage.getItem('appID') || ''
			}
			if (cookievalue) {
				obj['token'] = cookievalue;
			}
			if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
			return this.commonService.isApp() ? from(this.nativeHttp.post(this.scriptExcel.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
				return JSON.parse(response['data'] as any);
			})): this.httpClient.post(this.scriptExcel.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
		}

		getOptimumLink(authorization:any,userType: any, userCode: any,menuId: any){

			let params = {
				'User_type': userType,
				'User_code': this.cipherText.aesEncrypt(userCode),
				'Page_Id': menuId,
				'Prm_1': ''
			}
			
			let obj = {
				'Authorization': `Bearer ${authorization}`,
				'Content-Type': 'application/json',
				"Ocp-Apim-Subscription-Key": this.gatewaySubscriptionKey.invest,
				'appID': localStorage.getItem('appID') || ''
			};
			if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
			return this.commonService.isApp() ? from(this.nativeHttp.post(this.optimum.url, params, Object.assign(obj, this.headersParams))).pipe(map(response => {
				return JSON.parse(response['data'] as any);
			})) : this.httpClient.post(this.optimum.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
		}

		getinvestEdge(authorization:any){

			let formData = new FormData();
			formData.append('username', localStorage.getItem('userId1') ?? '');
			formData.append('iifl_token', this.cipherText.aesEncrypt(localStorage.getItem('jwt_token')));
			formData.append('iifl_cookie', this.cipherText.aesEncrypt(localStorage.getItem('brokerageToken')));
			
			let obj = {
				'Authorization': `Bearer ${authorization}`,
				'DeleteContentType': 'Yes',
				"Ocp-Apim-Subscription-Key": this.gatewaySubscriptionKey.invesEdge,
				'appID': localStorage.getItem('appID') || ''
			};
			if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
			return this.commonService.isApp() ? from(this.nativeHttp.post(this.investEdge.url, formData, Object.assign(obj, this.headersParams))).pipe(map(response => {
				return JSON.parse(response['data'] as any);
			})) : this.httpClient.post(this.investEdge.url, formData, { headers: new HttpHeaders(Object.assign(obj)) });
		}

		getinvestcurrentTime() {
			let params = {

			}
			let obj: any = {
				'Ocp-Apim-Subscription-Key' : "c94147460e83492392885a3482acf301",
			}
			
			if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
			return this.commonService.isApp() ? from(this.nativeHttp.get(this.investcurrentTime.url, {}, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
				return JSON.parse(response['data'] as any);
			})): this.httpClient.get(this.investcurrentTime.url, {headers: new HttpHeaders(Object.assign(obj)) });
		}

		getRealTimeMarginShortfall(Token:any, userID:any,isNegativeMargin?:any) {
			this.paramsObj = {
				"head": {
					"RequestCode": "CVUpdateLead01",
					"Key": this.getMarginShortfallVal.key,
					"AppName": this.getMarginShortfallVal.appName,
					"AppVer": "01",
					"OsName": "Android",
					"userType": localStorage.getItem('userType')
				},
				"body": {
					"PartnerCode": userID,
					"PageNo": "0",
					"SortBy": "",
					"SortOrder": "asc",
					"SearchBy": "",
					"SearchText": ""
				}
			}
			if (isNegativeMargin) {
				this.paramsObj.body['Type'] = "N"
			}
			let obj: any = {
				'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
				'appID': localStorage.getItem('appID') || ''
			};
			if (Token) {
				obj['token'] = Token;
			}
			if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
			return this.commonService.isApp() ? from(this.nativeHttp.post(this.getMarginShortfallVal.url, this.paramsObj, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
				return JSON.parse(response['data'] as any);
			})) : this.httpClient.post(this.getMarginShortfallVal.url, this.paramsObj, { headers: new HttpHeaders(Object.assign(obj)) });

		}

		getLivLong(cookievalue: any,details: any){
			let params = { 
				"apiKey": "LL.655598262a94ecec7ec888cae32d4054c4e.AAA", 
				"userName": localStorage.getItem('userId1'), 
				"requestData" : [{ "Name": details.Name },
								{ "Email": details.Email }] 
			}
			  let obj: any = {
				'appID': localStorage.getItem('appID') || ''
			};
			//   if (cookievalue) {
			// 	obj = {
			// 	  'token': cookievalue
			// 	}
			//   }
			if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
			return this.commonService.isApp() ? from(this.nativeHttp.post(this.getLivlongVal.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
				return JSON.parse(response['data'] as any);
			})): this.httpClient.post(this.getLivlongVal.url,params,{ headers: new HttpHeaders(Object.assign(obj))});
			 
		  }
		  
	public getScripSummaryReport(cookievalue: any,id: any,symbol: any): Observable<{}> {
		let params = {
			"head": {
			"RequestCode": "APIBO52UCVDWFY",
			"Key": this.scripSummaryReport.key,
			"AppName": this.scripSummaryReport.appName,
			"AppVer": "01",
			"OsName": "Android",
			"userType": localStorage.getItem('userType')
			}, 
			"body": {
				"loginid": id,
				"Symbol": symbol
			}
		};
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.scripSummaryReport.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.scripSummaryReport.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}		  

	public commoditySummaryList(cookievalue: any, data: any): Observable<{}> {
		let params = {
			"body": data,
			"head": {
				"RequestCode": "APIBO52UCVDWFY",
				"Key": this.commoSummary.key,
				"AppVer": "01",
				"AppName": this.commoSummary.appName,
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			}
		}
		let obj: any = {
			'appID': localStorage.getItem('appID') || '',
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.commoSummary.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.commoSummary.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getConsolidatedTradingReport(cookievalue: any, segment: any, date: any): Observable<{}> {
		let params = {
			"head": {
				"RequestCode": "CVUpdateLead01",
				"Key": this.tradingListRepo.key,
				"AppVer": "01",
				"AppName": this.tradingListRepo.appName,
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			},
			"body": {
				"loginid": localStorage.getItem('userId1'),
				"segment": segment,
				"asondate": date
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.tradingListRepo.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.tradingListRepo.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}
	public getCommodityClientScripSummary(cookievalue: any): Observable<{}> {
		let params = {
			"head": {
				"RequestCode": "CVUpdateLead01",
				"Key": this.commodityScripSum.key,
				"AppVer": "1.0.4.0",
				"AppName": this.commodityScripSum.appName,
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			},
			"body": {
				"loginid": localStorage.getItem('userId1')
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.commodityScripSum.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.commodityScripSum.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getVasDetailedReport(cookievalue: any): Observable<{}> {
		let params = {
			"head": {
				"RequestCode": "ProfileDetailsV1",
				"Key": this.vasDetailedReport.key,
				"AppVer": "1.0.4.0",
				"AppName": this.vasDetailedReport.appName,
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			},
			"body": {
				"loginid": localStorage.getItem('userId1'),
				"ClientCode": ""
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookievalue) {
			obj['token'] = cookievalue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.vasDetailedReport.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.vasDetailedReport.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}
	
	public getFreezeDetails = (cookieValue: any, freezeReason: any, loginId: any): Observable<{}> => {
		let params = {
			"head": {
				"RequestCode": "CVUpdateLead01",
				"Key": this.freezeDetails.key,
				"AppName": this.freezeDetails.appName,
				"AppVer": "1.0.4.0",
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			},
			"body":{
				"loginid": loginId,
				"srchby": "",
				"sortby": "",
				"sortorder": "",
				"freezereason": freezeReason,
				"pagenumber": "0"
			}
		}

		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if(cookieValue){
			obj['token'] = cookieValue;
		}
		if(this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.freezeDetails.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.freezeDetails.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	} 

	public getDpModificationReport = (cookieValue: any, loginId: any, dpType: any, fromDate: any, toDate: any) : Observable<{}> => {
		let params = {
			"head": {
				"RequestCode": "ProfileDetailsV1",
				"Key": this.dpModificationDetails.key,
				"AppName": this.dpModificationDetails.appName,
				"AppVer": "1.0.4.0",
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			},
			"body": {
				"LoginId": loginId,
				"ClientCode": "",
				"DPType": dpType,
				"FromDate": fromDate,
				"ToDate": toDate
			}
		}

		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if(cookieValue){
			obj['token'] = cookieValue;
		}
		if(this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.dpModificationDetails.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.dpModificationDetails.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getSettlementPayoutReport = (cookieValue: any, loginId: any): Observable<{}> => {
    	let checkSumKey = this.cipherText.generateCheckSumKey();
		let stringValue = `${loginId}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`;
		const checkSumValue = this.cipherText.createChecksum(stringValue,this.globalCheckSumkey);
		const loginID = this.cipherText.createChecksum(loginId ,checkSumKey);
		let params = {
			"head": {
				"RequestCode": "CVUpdateLead01",
				"Key": this.settlementPayoutReport.key,
				"AppVer": "01",
				"AppName": this.settlementPayoutReport.appName,
				"OsName": "Android",
				"CheckSum": checkSumValue,
				"CheckSumKey":checkSumKey
			},
			"body": {
				"LoginID": loginID
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookieValue) {
			obj['token'] = cookieValue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.settlementPayoutReport.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.settlementPayoutReport.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getDematRequestStatus = (cookieValue: any, loginId: any, pageNo: number, dpType: string, searchtxt? : any): Observable<{}> => {
		let params = {
			"head": {
				"RequestCode": "ProfileDetailsV1",
				"Key": this.dematRequestStatus.Key,
				"AppName": this.dematRequestStatus.appName,
				"AppVer": "1.0.4.0",
				"OsName": "Android"
			},
			"body": {
				"Partnercode": loginId,
				"PageNo": pageNo ,
				"Fliter": dpType,
				"SearchBy": searchtxt ? "ClientCode" : "",
				"SearchText": searchtxt ? searchtxt : ""
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookieValue) {
			obj['token'] = cookieValue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.dematRequestStatus.url, params, Object.assign(obj,this.nativeHttp))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.dematRequestStatus.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getSearchScripDetails = (cookieValue: any,searchText: any) : Observable<{}> => {
		let params = {
			"head": {
				"RequestCode": "ProfileDetailsV1",
				"Key": this.searchScripDetails.key,
				"AppName": this.searchScripDetails.appName,
				"AppVer": "1.0.4.0",
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			},
			"body": {
				"SrchTxt": searchText
			} 
		}

		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if(cookieValue){
			obj['token'] = cookieValue;
		}
		if(this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.searchScripDetails.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.searchScripDetails.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getAccountClosureStatus = (cookieValue: any, loginId: any, fromDate: any, toDate: any) : Observable<{}> => {
		let params = {
			"head": {
				"RequestCode": "ProfileDetailsV1",
				"Key": this.accountClosureStatus.key,
				"AppName": this.accountClosureStatus.appName,
				"AppVer": "1.0.4.0",
				"OsName": "Android",
				"userType": localStorage.getItem('userType')
			},
			"body": {
				"FromDate": fromDate,
				"ToDate": toDate,
				"PartnerCode": loginId,
				"ChildCode": "",
			}
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if(cookieValue){
			obj['token'] = cookieValue;
		}
		if(this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.accountClosureStatus.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.accountClosureStatus.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getDownloadDematForm = (cookieValue: any, dpType: any, downloadCount: any, loginId: any): Observable<{}> => {
		let params: any = {
			"rptId": dpType == 'CDSL' ? "14452" : "14453",
				"Type": dpType,
				"ClientCode": loginId,
				"downloadCount": downloadCount,
				"SendEmail": "N",
				"ReportFormat": "PDF",
				"CallFrom": "AAA"
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if (cookieValue) {
			obj['token'] = cookieValue;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.downloadDematForm.url, params, Object.assign(obj, this.nativeHttp))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.downloadDematForm.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public scriptExcelAumReport(data : any){
		const reqBody : any = 
		{
			"head":
			{
				"RequestCode": data.RequestCode,
				"Key": this.onlineSIPExcelReportDownload.key,
				"AppName": this.onlineSIPExcelReportDownload.appName,
				"AppVer": data.AppVer,
				"OsName": data.OsName
			},
			"body":
			{
				"LoginID": data.loginId,
				"FromDate": data.fromDate,
		    	"ToDate": data.toDate,	
			}	
		}
		if (data.RequestCode == "FetchClientDashBoardDetails" ) {
			reqBody.body.PartnerCode = data.PartnerCode;
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}
		if (data.token) {
			obj['token'] = data.token;
		}
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		let url = data.RequestCode == 'GetOnlineSIPReport' ? this.onlineSIPExcelReportDownload.url : this.aumExcelReportDownload.url
		return this.commonService.isApp() ? from(this.nativeHttp.post(url, reqBody, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(url, reqBody, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public getDisInwardDetails = (cookieValue: any, userId: any, dpType: any, fromDate: any, toDate: any) : Observable<{}> => {
		let params = {
			"head": {
				"RequestCode": "ProfileDetailsV1",
				"Key": this.disInwardDetail.key,
				"AppName": this.disInwardDetail.appName,
				"AppVer": "1.0.4.0",
				"OsName": "Android"
			},
			"body": {
				"PartnerCode": userId,
				"ChildCode": "",
				"DPType": dpType,
				"FromDate": fromDate,
				"ToDate": toDate
			}
		}

		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};
		if(cookieValue){
			obj['token'] = cookieValue;
		}
		if(this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.disInwardDetail.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.disInwardDetail.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}



	
	public getJVReqReason = (RequestType : any, ClientCode: any,token: any ) : Observable<{}> => {
	    let checkSumKey = this.cipherText.generateCheckSumKey()
		let stringValue
		if(RequestType.api == 'GetJVDetails'){
		  stringValue = `${localStorage.getItem('userId1')}_${ClientCode}_${RequestType.JvReason}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`;
		}else{
		   stringValue = `${localStorage.getItem('userId1')}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`;
		}
		const checkSumValue = this.cipherText.createChecksum(stringValue,this.globalCheckSumkey);
		const patnerCode = this.cipherText.createChecksum(ClientCode ,checkSumKey);
		const loginID = this.cipherText.createChecksum(localStorage.getItem('userId1') ,checkSumKey);


		let params = {
				"head": {
					"RequestCode": RequestType.requestCode,
					"Key": this.GetJVDetails.key,
					"AppName": this.GetJVDetails.appName,
					"AppVer": this.GetJVDetails.appVer,
					"OsName": this.GetJVDetails.osName,
					"CheckSum": checkSumValue,
					"CheckSumKey":checkSumKey
				},
				"body": {}as ParamsBody  // Assert body type to ParamsBody
		};

		if(RequestType.api == 'GetJVReversalReasonDepartment'){
			params.body.LoginId= loginID
		}
	    else if(RequestType.api == 'GetJVDetails'){

			params.body.LoginId= loginID,
			params.body.Reason = RequestType.JvReason
			params.body.ClientCode = patnerCode

		}else if(RequestType.api == 'GetJVReversalStatus'){
			params.body.LoginID = loginID,
			//params.body.SearchBy = RequestType.ReversalStatusData.clinetCode ? RequestType.ReversalStatusData.clinetCode : ""  ,
			params.body.SearchBy = RequestType.ReversalStatusData.Status !== '' ? 'status' : "" ,
			params.body.Searchtext = RequestType.ReversalStatusData.Status,
			params.body.FromDate = RequestType.ReversalStatusData.fromDate,
			params.body.ToDate = RequestType.ReversalStatusData.toDate
		}

		let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};

		if(token){
			obj['token'] = token;
		}
		
		
		if(this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(`${this.GetJVDetails.url}${RequestType.api}`, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
		return JSON.parse(response['data'] as any);
		 })) : this.httpClient.post(`${this.GetJVDetails.url}${RequestType.api}`, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public jvInsterDetails(cookievalue: any, jvDetails: any, jvRequest: any, ClientCode: any): any {
		let checkSumKey = this.cipherText.generateCheckSumKey();
		let stringValue = `${localStorage.getItem('userId1')}_${ClientCode}_${jvRequest.jvReason}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`;
		const checkSumValue = this.cipherText.createChecksum(stringValue, this.globalCheckSumkey);
		const patnerCode = this.cipherText.createChecksum(ClientCode, checkSumKey);
		const loginID = this.cipherText.createChecksum(localStorage.getItem('userId1'), checkSumKey);
		let array: any = [];
		const createJVObject = (res : any) => ({
			"ClientCode": patnerCode,
			"DocumentOrInvoiceNo": res.DocumentOrInvoiceNo || "",
			"Remark": res.Remark,
			"Date": res.Date ? res.Date.trim() : "",
			"Amount": res.Amount || "",
			"JVAmount": res.JVAmount || "",
			"Balance": res.Balance || "",
			"Reason": jvRequest.jvReason,
			"Department": jvRequest.jvDepartment || "",
			"ReversalAvailed": res.ReversalAvailed || "",
			"LoginID": loginID
		})

		if (jvRequest.jvReason == 'Dealing Error') {
			array.push(createJVObject({
				Remark: jvRequest.Remark,
				JVAmount: jvRequest.jvReversalAmount,
				Department: jvRequest.jvDepartment,
			}));
		} else {
			jvDetails.map((res: any) => {
				array.push(createJVObject(res));
			})
		}


		let params = {
			"head": {
				"RequestCode": "GetJVReversalReasonDepartment",
				"Key": this.jvInsert.key,
				"AppName": this.jvInsert.appName,
				"AppVer": this.jvInsert.appVer,
				"OsName": this.jvInsert.osName,
				"CheckSum": checkSumValue,
				"CheckSumKey": checkSumKey
			},
			"body": array
		}

		let obj: any = {
			'Ocp-Apim-Subscription-Key': this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		}

		if (cookievalue) {
			obj['token'] = cookievalue;
		}

		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.jvInsert.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.jvInsert.url, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	jvApproveRejectStatus(cookievalue: any, passObj: any){
		let checkSumKey = this.cipherText.generateCheckSumKey();
		let stringValue = `${localStorage.getItem('userId1')}_${passObj.ClientCode}_${passObj.Reason}_${passObj.UserLevel}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`;
		const checkSumValue = this.cipherText.createChecksum(stringValue,this.globalCheckSumkey);
		const patnerCode = this.cipherText.createChecksum(passObj.ClientCode ,checkSumKey);
		const loginID = this.cipherText.createChecksum(localStorage.getItem('userId1') ,checkSumKey);

		let params = {
			"head": {
			"RequestCode": "ApproveRejectJVRequest",
			"Key": this.jvStatusOptions.key,
			"AppName": this.jvStatusOptions.appName,
			"AppVer": this.jvStatusOptions.appVer,
			"OsName": this.jvStatusOptions.osName,
			"CheckSum" : checkSumValue,
			 "CheckSumKey" : checkSumKey
			},
			"body": passObj
		  }

		  params.body.ClientCode = patnerCode;
		  params.body.loginID = loginID;
		  
		  let obj: any = {
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		  };
		  if (cookievalue) {
			obj['token'] = cookievalue;
		  }

		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.jvStatusOptions.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})): this.httpClient.post(this.jvStatusOptions.url,params,{ headers: new HttpHeaders(Object.assign(obj))});
		 
	  }


}
