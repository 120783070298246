<div class="guest-journey-main">
<div class="pop_close" (click)="dismiss()"><img src="assets/svg/close_black.svg" width="18" alt="close"></div>

<div class="inner-content">
    <div class="inner-center ion-justify-content-center ion-align-items-center">
        <ng-container *ngIf="commonService.calculateOnboardingProgress(progressVal) == 0; else afterJ">            
            <img src="assets/svg/onboarding-modal-banner-before-start.svg" alt="banner" class="before-journey">
            <div class="before-journey">
                <h2>Get access to client report<br> and more benefits</h2>
            </div>          
        </ng-container>
        <ng-template #afterJ>          
            <img src="assets/svg/onboarding-modal-banner-after-start.svg" alt="banner" class="after-journey">
            <div class="after-journey">
                <h2>Oh, You're Few steps away to<br>become a partner with us</h2>
            </div>
            <div class="onboarding-after-progess after-journey">
               <div class="progress">
                    <ion-progress-bar class="main_progress_bar"  [value]="progressVal"></ion-progress-bar>
                </div>
                <div class="steps-timeline">
                    <span *ngIf="commonService.calculateOnboardingProgress(progressVal) == 0">
                        <span>5 steps are left</span>
                        <span class="step-done">0/5 done!</span>
                    </span>
                    <span *ngIf="commonService.calculateOnboardingProgress(progressVal) == 0.2">
                        <span>4 steps are left</span>
                        <span class="step-done">1/5 done!</span>
                    </span>
                    <span *ngIf="commonService.calculateOnboardingProgress(progressVal) == 0.4">
                        <span>3 steps are left</span>
                        <span class="step-done">2/5 done!</span>
                    </span>
                    <span *ngIf="commonService.calculateOnboardingProgress(progressVal) == 0.6">
                        <span>2 steps are left</span>
                        <span class="step-done">3/5 done!</span>
                    </span>
                    <span *ngIf="commonService.calculateOnboardingProgress(progressVal) == 0.8">
                        <span>1 steps are left</span>
                        <span class="step-done">4/5 done!</span>
                    </span>
                </div>
             </div>
        </ng-template>       
        <span>Complete onboarding process and become a partner with us</span> 
         <div [ngClass]="{'disabled': progressVal == 1}" class="orange-btn" (click)="onResumeOnboarding()">Resume Onboarding</div>
    </div> 
</div>
</div>
  
  
