import { Component, OnInit, Input, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Subscription, Subject, interval, forkJoin, throwError } from 'rxjs';
import { ClientTradesService } from '../../pages/recently-viewed-client-list/client-trades.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { OrderPipe } from 'ngx-order-pipe';
import { debounceTime, switchMap } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';
import { WireRequestService } from '../../pages/wire-requests/wire-requests.service';
import { Platform, PopoverController } from '@ionic/angular';
import { DashBoardService } from '../../pages/dashboard/dashboard.service';
import { CustomEncryption } from '../../../config/custom-encrypt';
import { FormatNumberDecimalPipe } from '../../helpers/decimalNumber.pipe';
import { FormatNumberDecimalCommaPipe } from '../../helpers/decimalNumberComma.pipe';
import { ShareReportService } from '../../pages/share-reports/share-report.service';
import { StorageServiceAAA } from '../../helpers/aaa-storage.service';
import { ToasterService } from '../../helpers/toaster.service';
import { CommonService } from '../../helpers/common.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
	selector: 'app-portfolio-pnl',
	providers: [CommonService, ClientTradesService,OrderPipe, DashBoardService, CustomEncryption, FormatNumberDecimalPipe, FormatNumberDecimalCommaPipe, WireRequestService, ShareReportService,],
	templateUrl: './portfolio-pnl.component.html',
  styleUrl: './portfolio-pnl.component.scss'
})
export class PortfolioPnlComponent implements OnInit {
  clientTabValue = "rmView";
	ParentClientCode: any;
	public moment: any = moment;
	portfolioData: any;
	clientCode: any;
	pnlClientCode: any;
	pnlClientName: any;
	clientEqData: any[] = [];
	mfSeg: any;
	displayClientDropDownField: boolean = true;
	clientToken: any;
	clientLoginId: any;
	mfTableDisplay: any[] = [];
	unRealisePlValue: any = 0;
	unRealisePlPer: any = 0;
	familyMappList: any[] = [];
	memberClientCode: any;
	selectRelation: any;
	displayMemberContent: boolean = true;
	memberData: any[] = [];
	filterProdDetail: any[] = [];
	parentClientCode: any;
	dataLoad?: boolean;
	tabPanelPnlLoader!: boolean;
	pdfLoader: boolean = true;
	stocksEquityValue = null;
	mfEquityValue = null;
	displayRelation = "FAMILY";
	pnlDisplayRelation = "SELF";
	clientMappMsg: boolean = false;
	chartMemberData = [];
	chartProdData = [];
	fdData: any[] = [];
	displayReport: boolean = false;
	parentClientName: any;
	private subscription: Subscription = new Subscription();
	isOpen = false;
	displayStep1: boolean = false;
	displayStep2: boolean = false;
	displayStep3: boolean = false;
	verifyBtn: boolean = true;
	familyOptionDisplay: boolean = true;
	public val: string = 'asc';
	isApkWebView = false;

	//Sorting
	public order!: string;
	public ascending: boolean = true;
	reverse: boolean = false;
	visible?: boolean;
	dropvisible?: boolean;
	clientSearchValue: any;
	portfolioUserId:any;
	isBrokingClient: boolean = false;
	clientType!: string;
	displayClientCode?: string;

	// GrandTotal Variables
	grandTotalMFpnl:any;

	public isDropDownVisible: boolean = false;
	@Input() public changeDetPage: any;
	// Anaytics Data
	eqAnalyData: any = [];
	mfAnalyData: any = [];
	clientCodeList: any[] = [];
	viewData: boolean = false;
	screenWidth:any;
	desktop: boolean;
	headerNative:  boolean = true;

	isPortfolioDownload: boolean = false;
	isProductDownload: boolean = false;
	displayDuration: boolean = false;
	userType: any;
	private clientSearchTerms = new Subject<string>();
	dtLoad: boolean = false;
	displayMainDropDown:boolean = false;
	initUserId:any;
	subscribeOtp!: Subscription;
	
	selectedReport: any = [];
	dateRangeType: any = 'yearWise';
	yearRangeValue: any;
	fromDate: any;
	toDate: any;
	financialYrList: any[] = [];
	myOptions: any = {
		dateFormat: 'dd/mm/yyyy',
		showMonthNumber: false
	}
	Loadvalue = false;
	inputattr = false;
	realizedPlData:any = [];
	familyMemberList:any = [];
	family_member_value: any;
	realizedClientCode:any;
	passDisplayAnalyticsSection: boolean = false;
	currentTab: string = "Portfolio_tab";
	clientId: any;
	selectedDropdownClient: any;
	ionFromDate: any;
	ionToDate: any;
	currentDate: any;
	isDownloadDatePicker: boolean = false;
	tabPanelPnlTable: string = "";
	dividendTableList: { 
		Total_Dividend_Amount: string,
		EquityDividend: {
			ClientCode: string,
			ScripName: string,
			ExDate: string,
			Dividend_Amount: string,
		}[]
	} = { Total_Dividend_Amount: "0", EquityDividend: []}
	displayPopup: boolean = false;
	storeProducts:any = [];
	productLastUpdatedDate:any;
	latestClientCode: boolean = false;
  detailHeight: any;
  detail: any;
	portfolioId: any;

	constructor(@Inject(DOCUMENT) private document: Document,
		private clientService: ClientTradesService, private storage: StorageServiceAAA, private ciphetText: CustomEncryption, private router: Router, public toast: ToasterService,
		private sanitize: DomSanitizer, private orderPipe: OrderPipe, private formatNumDecimal: FormatNumberDecimalPipe, private formatNumDecimalComma: FormatNumberDecimalCommaPipe, private commonService: CommonService, private popoverController: PopoverController, private dashBoardService: DashBoardService,
		private platform: Platform, private route: ActivatedRoute, private locationSt: LocationStrategy, private wireReqService: WireRequestService, private shareReportSer: ShareReportService, private elementRef: ElementRef,private cookieService: CookieService
	) {
 

    //let clientDetails = this.router.getCurrentNavigation()?.extras?.state;
    this.route.queryParams.subscribe((params: any) => {
		this.pnlClientName = params?.['clientName'];
		this.getClientCode = params?.['clientCode'];
		this.displayClientCode = params?.['clientCode'];
		this.isApkWebView =  params?.['isApkWebView'];
    });
    

		if (this.platform.is('desktop')) {
			this.desktop = true
		}
		else {
			this.desktop = false
		}
		if (location.pathname == '/family-portfolio') {
			history.pushState(null, "", window.location.href);
			this.locationSt.onPopState(() => {
				history.pushState(null, "", window.location.href);
			});
		}
    this.storage.get('userType').then(type => {
      if (type === 'RM' || type === 'FAN') {
        this.storage.get('userID').then((userId) => {
          this.storage.get('sToken').then(token => {
            this.portfolioToken = token;
            this.portfolioUserId = this.ciphetText.aesEncrypt(userId);
            this.initUserId = this.ciphetText.aesEncrypt(userId);
            this.initPortfolio()

          })
        })
      }
      else {
        this.storage.get('userID').then((userId) => {
          this.storage.get('subToken').then(token => {
            this.portfolioToken = token;
            this.portfolioUserId = this.ciphetText.aesEncrypt(userId);
            this.initUserId = this.ciphetText.aesEncrypt(userId);
            this.initPortfolio()

          })
        })
      }
    })
	

	}


	
    
	ionViewWillEnter() {
		this.clientSearchValue = null;
		this.viewData = false;
		let token = localStorage.getItem('jwt_token')
		let userId1 = localStorage.getItem('userId1');
		this.clientCodeList = [];
		this.storage.get('userType').then(type => {
			if (type === 'RM') {
				this.userType = type;
			} else if (type === 'FAN') {
				this.userType = 'FN';
			} else {
				this.userType = 'SB';
			}
		})
		this.clientSearchTerms
			.pipe(
				debounceTime(500),
				switchMap((searchValue) => this.dashBoardService.fetchGetClientCodes(this.userType, userId1, token, searchValue)))
			.subscribe(results => {
				let clientData = [].concat(...results);
				const data = clientData;
			});
			this.setInitialDates();
		this.currentDate = moment(new Date()).format("YYYY-MM-DD");
		this.commonService.setClevertapEvent('ReportfamilyPortfolio_Clicked', { 'Login ID': localStorage.getItem('userId1') });
		this.commonService.triggerAppsflyerLogEvent('Report_FamilyPortfolio_Clicked', { 'Login ID': localStorage.getItem('userId1') });
		
		if (location.pathname == '/client-portfolio') {
			localStorage.removeItem('tokenData')
			localStorage.removeItem('clientLoginId')

		
			if (localStorage.getItem('saveClientId')) {
				this.viewData = false;
				this.getClientCode = localStorage.getItem('familyClientId');
			setTimeout(() => {
				this.initPortfolio();
				this.viewData = true;
			}, 1000);
			}
		}

		if (location.pathname == '/family-portfolio') {
			this.displayClientDropDownField = false;
			this.route.queryParams.subscribe((params: any) => {
			setTimeout(() => {
					if(params && params.id && params.TOKEN){
						localStorage.setItem('portfolioId',params.id);
						localStorage.setItem('portfolioToken',params.TOKEN);
						this.cookieService.set('CC',params.id);
						this.cookieService.set('.ASPXAUTH',params.TOKEN);
					}
					else{
						let id = this.cookieService.get('CC');
						let token = this.cookieService.get('.ASPXAUTH');
						localStorage.setItem('portfolioId',id);
						localStorage.setItem('portfolioToken',token);
					}

					let key = [78, 86, 69, 73, 78, 69, 38, 42, 60, 49, 64, 84, 79, 102, 46, 42];
					let iv = [83, 71, 26, 58, 54, 35, 22, 11, 83, 71, 26, 58, 54, 35, 22, 11];
					let encryClientID;
					let passToken;
					let mobileHeader;
					if(!this.desktop){
						encryClientID = localStorage.getItem('portfolioId') ? localStorage.getItem('portfolioId') : params.id;
						passToken = localStorage.getItem('portfolioToken') ? localStorage.getItem('portfolioToken') : params.TOKEN;
						mobileHeader = localStorage.getItem('mobileNative') ? localStorage.getItem('mobileNative') : params.Mobilenative;
						if(mobileHeader == "yes"){
							this.headerNative = false;
						}
						else{
							this.headerNative = true;
						}
					}
					else{
						encryClientID = localStorage.getItem('portfolioId');
						passToken = localStorage.getItem('portfolioToken');
					}
					if (!(encryClientID && passToken)) {
						this.router.navigate(['/not-found']);
						return;
					}
					let textValue = this.ciphetText.getDecryptedValue(encryClientID.replace(/\s+/g, "+"), key, iv);
					let fetchClientId = textValue.substring(0, 8);
					//this.viewData = true;
					this.portfolioUserId = "";
				}, 3000);

			})


		}
		localStorage.removeItem('saveClientId')
		localStorage.removeItem('totalHoldings');
		this.eqAnalyData = [];
	}

	getCurrent_FinancialYear() {
		var financial_year = "";
		var today = new Date();
		if ((today.getMonth() + 1) <= 3) {
			financial_year = (today.getFullYear() - 1) + "-" + today.getFullYear()
		} else {
			financial_year = today.getFullYear() + "-" + (today.getFullYear() + 1)
		}
	}

	getFinancial_YearList(year1: any, year2: any) {
		var financialYear = [];
		for (var i = 0; i < 6; i++) {
			financialYear.push((year1 - i) + '-' + (year2 - i))
		}
		return financialYear
	}


	sliderIndex: number = 0;
	isLastIndex: boolean = false;

	setInitialDates(){
		this.financialYrList = this.getFinancialYearList(this.getCurrentFinancialYear().split('-')[0], this.getCurrentFinancialYear().split('-')[1])
		this.yearRangeValue = this.financialYrList[0];
		this.fromDate = new Date(this.yearRangeValue.split('-')[0] + '-' + '04' + '-' + '01');
		this.ionFromDate = this.commonService.getIonDateTimeFormat(this.fromDate);
		this.toDate = new Date();
		this.ionToDate = this.commonService.getIonDateTimeFormat(this.toDate);
		var tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		this.myOptions['disableSince'] = { year: moment(tomorrow).format('YYYY'), month: moment(tomorrow).format('MM'), day: moment(tomorrow).format('DD') }
	}
	urlParameter: any;

	ngOnInit() {
    this.storage.get('userType').then(type => {
      if (type === 'RM' || type === 'FAN') {
        this.storage.get('userID').then((userId) => {
          this.storage.get('sToken').then(token => {
            this.portfolioToken = token;
            this.portfolioUserId = this.ciphetText.aesEncrypt(userId);
            this.initUserId = this.ciphetText.aesEncrypt(userId);

          })
        })
      }
      else {
        this.storage.get('userID').then((userId) => {
          this.storage.get('subToken').then(token => {
            this.portfolioToken = token;
            this.portfolioUserId = this.ciphetText.aesEncrypt(userId);
            this.initUserId = this.ciphetText.aesEncrypt(userId);
          })
        })
      }
    })
		this.segmentChangedWkMonth("1M")
		this.financialYrList = this.getFinancialYearList(this.getCurrentFinancialYear().split('-')[0], this.getCurrentFinancialYear().split('-')[1]);
		this.yearRangeValue = this.financialYrList[0];
		this.screenWidth = window.innerWidth;
		this.subscription = new Subscription();
		if(this.desktop || this.screenWidth > 1360){
			setTimeout(() => {
				location.search.replace('?id' + localStorage.getItem('portfolioId') + '?TOKEN' + localStorage.getItem('portfolioToken') + '?Mobilenative' + localStorage.getItem('mobileNative'), '');
				this.router.navigate(
					[],
					{
						relativeTo: this.route,
						queryParams: {
							'id': null,
							'TOKEN': null,
							'Mobilenative':null
						},
						queryParamsHandling: 'merge', // remove to replace all query params by provided
					});
	
			}, 1500);
		}
	}

	//  Select the financial Yr from year dropdown

	select_FamilyMember(event: any) {
		this.realizedClientCode = this.familyMappList.find((item) => item.ClientName === event).ClientCode;
	}

	getCurrentFinancialYear() {
		var financial_year = "";
		var today = new Date();
		if ((today.getMonth() + 1) <= 3) {
			financial_year = (today.getFullYear() - 1) + "-" + today.getFullYear()
		} else {
			financial_year = today.getFullYear() + "-" + (today.getFullYear() + 1)
		}
		return financial_year;
	}

	getFinancialYearList(year1: any, year2: any) {
		var financialYear = [];
		for (var i = 0; i < 6; i++) {
			financialYear.push((year1 - i) + '-' + (year2 - i))
		}
		return financialYear
	}

	portfolioToken: any;


	initPortfolio(){
		this.clientCode = this.getClientCode;
		this.pnlClientCode = this.getClientCode;
		this.parentClientCode = this.displayClientCode;
		this.getFamilyDropdown(	this.ciphetText.aesEncrypt(this.displayClientCode))
	
		this.displayRelation = 'FAMILY';
	}

	clientDetails: any;

	getClientCode:any;

	displayClientDetails(data: any) {
	}

	hideDropDown(){
		setTimeout(() => {
			this.isDropDownVisible = false;
		}, 300);
	}

	showDropDown(){
		this.clientSearchValue = null;
		this.isDropDownVisible = true;
	}

	getFamilyDropdown(id: any) {
		this.subscription.add(
			this.clientService.getFamilyMapping(this.portfolioToken, id, this.portfolioUserId, this.clientType = "")
				.subscribe((res: any) => {
					this.familyOptionDisplay = true;
					if (res['Head']['ErrorCode'] == 0) {
						if (res['Body']['FamillyMapp'].length == 1) {
							if (res['Body']['FamillyMapp'][0]['Successflag'] == 'N') {
								this.familyMappList = [];
								this.dataLoad = true;
								this.clientMappMsg = true
							}
							if (res['Body']['FamillyMapp'][0] && res['Body']['FamillyMapp'][0]['ParentClientCode'].length == 0) {
							this.pnlClientName =  res['Body']['FamillyMapp'][0]['ClientName'];
								if(this.isBrokingClient) this.displayClientCode = res['Body']['FamillyMapp'][0]['ClientCode'];
								this.parentClientName = res['Body']['FamillyMapp'][0]['ClientName'];
								this.familyMappList = res['Body']['FamillyMapp'];
							}
							else {
								this.pnlClientName = res['Body']['FamillyMapp'][0]['ClientName'] ? res['Body']['FamillyMapp'][0]['ClientName'] : "Name Not Available"
								if(this.isBrokingClient) this.displayClientCode = res['Body']['FamillyMapp'][0]['ClientCode'];
								this.parentClientName = res['Body']['FamillyMapp'][0]['ClientName'];
								this.familyMappList = res['Body']['FamillyMapp'];
							}
							this.displayRelation = this.familyMappList[0]['Relation'] && this.familyMappList[0]['Relation'].length > 0 ? this.familyMappList[0]['Relation'] : 'SELF';
							
						}
						else {
							this.familyOptionDisplay = true;
							this.familyMappList = res['Body']['FamillyMapp'];
						this.familyMappList.forEach((member) => {
								if(member.Relation.toString().toUpperCase() == "SELF"){
									this.pnlClientName = member.ClientName;
									if(this.isBrokingClient) this.displayClientCode = member.ClientCode;
									this.parentClientName = member.ClientName;
								}
							});
						}
						if (this.familyMappList && this.familyMappList.length > 0) {
							this.familyMappList.forEach(element => {
								element.Relation = element.Relation && element.Relation.length == 0 ? 'SELF' : element.Relation
							})
						}
						setTimeout(() => {
							this.displayReport = true;
						}, 5000);
					}
					else {
						this.familyMappList = [];
						this.pnlClientName = null;
						if(this.isBrokingClient) this.displayClientCode = undefined;
						this.clientMappMsg = true;
						this.dataLoad = true;
						this.parentClientName = null;
					}
				})
		)
	}



	numberFormat(value: any) {
		return this.formatNumDecimal.transform(value);
	}

	formatDecimal(value: any) {
		return value.toFixed(2);
	}

	numberNoFormat(value: number) {
		return this.formatNumDecimalComma.transform(value);
	}


	familyDropdown(obj: any) {
		if(!this.desktop){
			var element: any = this.document.getElementById("ClientMainBox");
			element.classList.toggle("d-none");
		}
		this.clientCode = obj['ClientCode'];
		this.displayClientCode = this.isBrokingClient ? this.clientCode : this.displayClientCode;
		this.portfolioUserId = this.ciphetText.aesEncrypt(obj.PartnerCode);
		this.displayRelation = obj['Relation'];
		let childCode = this.ciphetText.aesEncrypt(obj['ClientCode']);
		let parentClientId = this.isBrokingClient ? this.ciphetText.aesEncrypt(this.parentClientCode) : this.parentClientCode;
	}



	portfolioSummary:any[] = [];
	displayPdf:boolean = true;
	familyTotalInvestment:any = 0;
	familyHoldTotalPer:any = 0;
	isPopoverOpen:boolean = false

	familyHoldingDist:any[] = [];

	LbValue:any;
	pnldropMain: boolean = false;

	clientClickpnl(){
		this.pnldropMain = !this.pnldropMain;
	}

	clientHoverpnl() {
		this.pnldropMain = true;
	}

	clientLeavepnl() {
		this.pnldropMain = false;
	}
	
	removeHover(e: any){
		this.displayMainDropDown = false;
		if(this.desktop){
			var element: any = this.document.getElementById("ClientMainBox");
			element.classList.add("d-none");
		}
	}
	removeHoverpnl(e: any){
		if(this.desktop){
			var element: any = this.document.getElementById("ClientMainBoxPnl");
			element.classList.add("d-none");
		}
		
	}

	myClientselect(e: any) {
		if(this.desktop){
			var element: any = this.document.getElementById("ClientMainBox");
			element.classList.remove("d-none");
		}
		
	}
	myClientselectpnl(e: any) {
		
		if(this.desktop){
			var element: any = this.document.getElementById("ClientMainBoxPnl");
			element.classList.remove("d-none");
		}		
	}

	productTypeValue:any;

	getShortName(value: any){
		if(value && value.length){
			var shortName = value.match(/\b(\w)/g); // ['J','S','O','N']
			return shortName.join('');
		}

	}
	mfSecData: any = [];


	convertFunc(val: any){
        if(val){
            let value = parseFloat(val);
            return parseFloat(value.toFixed(2));
        }
		return val;
    }

	formatChange(date: any, format? : string){

		if(format) return moment(date).format(format);
        return moment(date).format('DD/MM/YYYY');
    }

	//Sorting
	setOrder(value: string) {
		this.reverse = !this.reverse;
		this.order = value;
		if (this.reverse) {
			this.ascending = false;
			this.val = 'desc';
		} else {
			this.ascending = true;
			this.val = 'asc';
		}
	}

	goBack(){
		localStorage.setItem('fromConnected','true')
		if(this.familyMappList.length == 0){
			localStorage.setItem('familyListConnected', JSON.stringify(this.getClientCode))

		} else {
			localStorage.setItem('familyListConnected', JSON.stringify(this.familyMappList[0].ClientCode))

		}
    	window.history.back();
	}

	toggleDateRange(value: any) {
		this.dateRangeType = value;
		if (value == 'dateWise') {
			this.fromDate = this.commonService.lastMonthISOConverted('previous');
			this.ionFromDate = this.commonService.getIonDateTimeFormat(this.fromDate);
			this.toDate = this.commonService.lastMonthISOConverted('current');
			this.ionToDate = this.commonService.getIonDateTimeFormat(this.toDate);
		}
		else {
			this.yearRangeValue = this.financialYrList[0];
			this.fromDate = new Date(this.yearRangeValue.split('-')[0] + '-' + '04' + '-' + '01');
			this.ionFromDate = this.commonService.getIonDateTimeFormat(this.fromDate);
			this.toDate = new Date();
			this.ionToDate = this.commonService.getIonDateTimeFormat(this.toDate);
		}
	}
	callRealizedPlAPIs(token?: any) {
		this.pdfLoader = false;

		let payload = {
			clientCode: this.realizedClientCode,
			fromDate: moment(this.fromDate).format('YYYYMMDD'),
			ToDate: moment(this.toDate).format('YYYYMMDD'),
			product: '',
		}
		const apiCalls = [];
		for (let i = 1; i <= 4; i++) {
			if (i === 1) {
				payload.product = 'cash';
			} else if (i === 2) {
				payload.product = 'f&o';
			} else if (i === 3) {
				payload.product = 'currency';
			} else if (i === 4) {
				payload.product = 'commodity';
			}
			const observable = this.shareReportSer.getRealizedPl(token, payload);
			apiCalls.push(observable);
		}
		forkJoin(apiCalls)
			.subscribe(
				results => {
					results.forEach((res, index) => {
						if (res['Head']['ErrorCode'] == 0) {
							if (res['Body']['Realized'].length > 0) {
								this.realizedPlData = [...this.realizedPlData, {
									"pageTitle": "P&L Statement",
									"pageNumber": "4",
									"subTitle": `${index === 0 ? 'Cash' : index === 1 ? 'f&o' : index === 2 ? 'currency' : 'commodity'}`,
									"hasTable": true,
									"tableHead": [
										"Scrip Name",
										"ISIN",
										"Qty",
										"Buy Avg Rate",
										"Buy Charges",
										"Buy Value",
										"Sell Avg Rate",
										"Sell Charges",
										"Sell Value",
										"Long Term PL",
										"Short Term PL",
										"Intraday PL",
										"Total PL",
									],
									"totalData": res['Body']['Realized'],
								}]
							}
						}
						if (index === 3) {
							this.callMFPNLStatement();
							index++;
						}
						if (index === 4) {
							this.GetTotalEquityDividend("1", true);
						}
					});
				},
				error => {
					console.error('Error in one or more API calls:', error);
				}
			);
	}

	callMFPNLStatement = () => {
		const start_date = moment(this.fromDate).format('DD/MM/YYYY'); // from_Date
		const end_date = moment(this.toDate).format('DD/MM/YYYY'); // to_Date
		this.subscription.add(
			this.clientService.getMFPNLStatement(this.portfolioToken, this.clientCode, start_date, end_date, localStorage.getItem('userId1'),
				this.clientType)
				.subscribe((res: any) => {
					if (res['Head']['ErrorCode'] == 0) {
						let consRealizedPlList = [];
						const reportArray = this.selectedReport.map((item: any) => item.value);
						if (res['Body']['Table3'] && res['Body']['Table3'].length > 0) {
							var result = this.groupBy1(res['Body']['Table3'], function (item: any) {
								return [item.Scheme_Name];
							});
							const grandTotalMFpnl = res['Body']['Table3'][res['Body']['Table3'].length - 1];
							for (var j in result) {
								if (result[j]["Scheme_Name"] !== "") { // review
									var combineObj = {
										"srNo": parseInt(Object.keys(result)[j]),
										"Scheme_Name": result[j][0].Scheme_Name,
										"TransactionType": result[j][0].Transaction_Type,
										"Transaction_Type": "",
										"Transaction_Date": "",
										"Purchase_Price": "",
										"Units": result[j].reduce((el: any, li: any) => el + parseFloat(li.Units), 0),
										"Purchase_Amount": result[j].reduce((el: any, li: any) => el + parseFloat(li.Purchase_Amount), 0),
										"Sell_Date": "",
										"Sell_Type": "",
										"Sell_Rate": "",
										"Sell_Amount": result[j].reduce((el: any, li: any) => el + parseFloat(li.Sell_Amount), 0),
										"Days": "",
										"GL_ST_Debt": result[j].reduce((el: any, li: any) => el + parseFloat(li.GL_ST_Debt), 0),
										"GL_ST_Equity": result[j].reduce((el: any, li: any) => el + parseFloat(li.GL_ST_Equity), 0),
										"GL_LT_Debt": result[j].reduce((el: any, li: any) => el + parseFloat(li.GL_LT_Debt), 0),
										"GL_LT_Equity": result[j].reduce((el: any, li: any) => el + parseFloat(li.GL_LT_Equity), 0),
										"STT": "",
										"ISIN": "",
										"RIA_FLAG": "",
										"isSumRow": true
									};
									consRealizedPlList.push(combineObj);
									consRealizedPlList.push(...result[j]);
								}
							}
							consRealizedPlList = [...consRealizedPlList.sort((a, b) => (a.ScripName > b.ScripName) ? -1 : 1), {
								...grandTotalMFpnl,
								INSTRUMENTNAME: "Total"
							}];
							this.realizedPlData = [...this.realizedPlData, {
								"pageTitle": "P&L Statement",
								"pageNumber": "4",
								"subTitle": 'MF',
								"hasTable": true,
								"tableHead": [
									"Scheme Name",
									"Transaction Type",
									"Purchase Price",
									"Units",
									"Purchase Amount",

									"Sell Type",
									"Sell Rate",
									"Sell Amount",
									"Days",
									"G/L ST(Debt)(INR)",
									"G/L ST (Equity)(INR)",
									"G/L LT(Debt)(INR)",
									"G/L LT (Equity)(INR)",
									"STT(INR)",
								],
								"totalData": consRealizedPlList,
							}]
						} else {
							consRealizedPlList = [];
						}
					}
				})
		)
	}


	selectReportType(data: any) {
		if (!data.isChecked) {
			this.selectedReport = [...this.selectedReport, data];
		} else {
			this.selectedReport = this.selectedReport.filter((item: any) => item.value !== data.value)
		}
		if (data.value === 'pl') {
			this.family_member_value = this.familyMappList[0].ClientName;
			this.realizedClientCode = this.familyMappList[0].ClientCode;
			this.familyMemberList = this.familyMappList.map((item)=>item.ClientName);
			this.dateRangeType = 'yearWise';
			this.displayDuration = !data.isChecked;
			this.setInitialDates();
		}
	}

	// pnl revamp //
	yearDateOption: string = 'yearWise';
	realizedPlObj: any
	fromDateReq: any;
	toDateReq: any;
	//  Select the value of 1month or 1week
	segmentChangedWkMonth(event: any) {
		if (event == '1M') {
			this.fromDate = this.commonService.lastMonthISOConverted('previous');
			this.ionFromDate = this.commonService.getIonDateTimeFormat(this.fromDate);
			this.toDate = this.commonService.lastMonthISOConverted('current');
			this.ionToDate = this.commonService.getIonDateTimeFormat(this.toDate);
		}
		else {
			this.fromDate = this.commonService.lastWeekISOConverted('last');
			this.ionFromDate = this.commonService.getIonDateTimeFormat(this.fromDate);
			this.toDate = this.commonService.lastWeekISOConverted('first');
			this.ionToDate = this.commonService.getIonDateTimeFormat(this.toDate);
		}
	}
	//  Select the Segment Type
	segmentChangedYrDate(event: any) {
		if (event == 'dateWise') {
			this.fromDate = this.commonService.lastMonthISOConverted('previous');
			this.ionFromDate = this.commonService.getIonDateTimeFormat(this.fromDate);
			this.toDate = this.commonService.lastMonthISOConverted('current');
			this.ionToDate = this.commonService.getIonDateTimeFormat(this.toDate);
		}
		else {
			this.yearRangeValue = this.financialYrList[0];
			this.fromDate = new Date(this.yearRangeValue.split('-')[0] + '-' + '04' + '-' + '01');
			this.ionFromDate = this.commonService.getIonDateTimeFormat(this.fromDate);
			this.toDate = new Date();
			this.ionToDate = this.commonService.getIonDateTimeFormat(this.toDate);
		}
		if(window.innerWidth <800){
			this.realisedPnlApiCall();
			this.getMFPNLStatement();
		} else {
			this.disablePnlTabs();
		}
	}
	
	//  Select the financial Yr from year dropdown
	selectYrFromList(event: any) {
		if (event == this.getCurrentFinancialYear()) {
			this.fromDate = new Date(this.yearRangeValue.split('-')[0] + '-' + '04' + '-' + '01');
			this.ionFromDate = this.commonService.getIonDateTimeFormat(this.fromDate);
			this.toDate = new Date();
			this.ionToDate = this.commonService.getIonDateTimeFormat(this.toDate);
		}
		else {
			this.fromDate = new Date(event.split('-')[0] + '-' + '04' + '-' + '01');
			this.ionFromDate = this.commonService.getIonDateTimeFormat(this.fromDate);
			this.toDate = new Date(event.split('-')[1] + '-' + '03' + '-' + '31');
			this.ionToDate = this.commonService.getIonDateTimeFormat(this.toDate);
		}
		this.disablePnlTabs();
	}
	productTypeList: any[] = [
		{ option: 'Cash', value: 'Cash', selected: false },					// [0]
		{ option: 'Currency', value: 'currency', selected: false },			// [1]
		{ option: 'Commodity', value: 'commodity', selected: false },		// [2]
		{ option: 'F&O', value: 'f&o', selected: false },					// [3]
		{ option: 'MF', value: 'MF', selected: false },						// [4]
		{ option: 'Dividend', value: 'dividend', selected: false },			// [5]
	]
	productValue = 'Cash';
	
	// Submit on Report Button in web view
	onDateChanged(event: any,dateType: any) {
		this.disablePnlTabs();
	}

	pnlDropdownClicked = (obj: any) => {
		this.pnlClientCode = null;
		this.pnldropMain = false;
		
		this.pnlClientName = obj['ClientName'] ? obj['ClientName'] : "Name Not Available";
		this.pnlClientCode = obj['ClientCode'];
		this.pnlDisplayRelation = obj['Relation'];
		//this.realisedPnlApiCall();
		this.disablePnlTabs();
		if(!this.desktop){
			var element: any = this.document.getElementById("ClientMainBoxPnl");
			element.classList.toggle("d-none");
		}
	}
	panelActive(type: string){
		type = type.toLowerCase();
		this.productValue = type;
		this.productTypeList[0].selected = false;
		this.productTypeList[1].selected = false;
		this.productTypeList[2].selected = false;
		this.productTypeList[3].selected = false;
		this.productTypeList[4].selected = false;
		this.productTypeList[5].selected = false;
		if(this.portfolioToken == null){
			this.portfolioToken = '.ASPXAUTH='+localStorage.getItem('portfolioToken');
			this.portfolioUserId = ""
			this.portfolioId = localStorage.getItem('portfolioId');
			if(!(this.portfolioToken && this.portfolioId)){
				this.router.navigate(['/not-found']);
				return;
			}
		}
		setTimeout(() => {
			if(type == 'mf'){
				this.productTypeList[4].selected = true;
				// api call
				this.tabPanelPnlTable = "mf";
				this.getMFPNLStatement();
			} 
			else if(type == 'dividend'){

				this.productTypeList[5].selected = true;
				this.tabPanelPnlTable = "dividend";
				this.GetTotalEquityDividend();
			} else {
				this.tabPanelPnlTable = "realised";
				if(type == 'cash'){
					this.productTypeList[0].selected = true;
				}
				else if(type == 'currency'){
					this.productTypeList[1].selected = true;
				}
				else if(type == 'commodity'){
					this.productTypeList[2].selected = true;
				}
				else if(type == 'f&o'){
					this.productTypeList[3].selected = true;
				}
				this.realisedPnlApiCall();
			}
		},100);
	}
	panelActiveMobile(type:any){
		if(this.portfolioToken == null){
			this.portfolioToken = '.ASPXAUTH='+localStorage.getItem('portfolioToken');
			this.portfolioUserId = ""
			this.portfolioId = localStorage.getItem('portfolioId');
			if(!(this.portfolioToken && this.portfolioId)){
				this.router.navigate(['/not-found']);
				return;
			}
		}
		if(window.innerWidth <800){
			this.productValue = type;
			setTimeout(() => {
				if(type == 'MF'){
					this.tabPanelPnlTable = "mf";
					this.getMFPNLStatement();
				} else if(type == "dividend"){
					this.tabPanelPnlTable = "dividend";
					this.GetTotalEquityDividend();
				}else {
					this.productValue = type;
					this.tabPanelPnlTable = "realised";
					this.realisedPnlApiCall();
				}
			},100);
		}
	}

	realisedPnlApiCall() {
		this.fromDateReq = this.fromDate;
		this.toDateReq = this.toDate;
		if (this.pnlClientCode) {
			//if (this.selectedShareType == 'realised') {
				this.commonService.setClevertapEvent('Realised PnL', { 'Login ID': localStorage.getItem('userId1') });
				this.commonService.triggerAppsflyerLogEvent('Report_FamilyPortfolio_PnL_Clicked', { 'Login ID': localStorage.getItem('userId1') });
				if (this.commonService.convertDateToMillisec(this.fromDateReq) > this.commonService.convertDateToMillisec(this.toDateReq)) {
					this.toast.displayToast('from Date cannot be more than To Date');
				}
				else {
					this.realizedPlObj = {}
					this.realizedPlObj.clientCode = this.pnlClientCode
					this.realizedPlObj.fromDate = moment(this.fromDateReq).format('YYYYMMDD')
					this.realizedPlObj.ToDate = moment(this.toDateReq).format('YYYYMMDD')
					this.realizedPlObj.product = this.productValue;
					this.realizedPlObj.portfolioToken = this.portfolioToken;
					this.realizedPlObj.callFrom360 = true;
					this.commonService.setClevertapEvent('Realised_PnL');
					this.tabPanelPnlTable = "realised";
				}
		} else {
			this.toast.displayToast('Please Enter the Client Code');
		}
	}

	disablePnlTabs = () => {
		this.productValue = "Cash";
		this.productTypeList[0].selected = false;
		this.productTypeList[1].selected = false;
		this.productTypeList[2].selected = false;
		this.productTypeList[3].selected = false;
		this.productTypeList[4].selected = false;
		this.productTypeList[5].selected = false;
		this.tabPanelPnlTable = "";
		this.grandTotalMFpnl = {};
	}


	checkIfSelf = (relation: string) => {
		if(relation == "" || relation.toLowerCase() == "self"){
			return false;
		}
		return true;
	}

	hideDatePicker( type: string, event?:any) {
		// Update selectedDate with the changed value

			if (type === 'start') {
				if(event != undefined){
					this.onStartDateChanged();
				}
			} else if (type === 'end') {
				if(event != undefined){
					this.onEndDateChanged1();
				}
			} else if (type.toLowerCase() == "till"){
				if(event != undefined){
					this.onStartDateChanged();
				}
			}
	}

	onStartDateChanged() {
		if(new Date(this.ionFromDate) > new Date(this.ionToDate)){
			this.toast.displayToast("From date cannot be greater than To Date");
			this.segmentChangedWkMonth("1M");
			return;
		}
		this.fromDate = new Date(this.ionFromDate);
	}
	// new code end date
	onEndDateChanged1() {
		if(new Date(this.ionFromDate) > new Date(this.ionToDate)){
			this.toast.displayToast("From date cannot be greater than To Date");
			this.segmentChangedWkMonth("1M");
			return;
		}
		this.toDate = new Date(this.ionToDate);
	}

	dropClick(sr:any, arr:any) {
		// event.preventDefault();
		arr.forEach((element:any, ind:any) => {
			if (sr !== element.srNo) {
				element['isVisible'] = false;
			} else {
				element['isVisible'] = element['isVisible'] ? false : true;
				if (element['isVisible']) {
					setTimeout(() => {
						this.detailHeight = this.detail?.nativeElement.offsetHeight;
					}, 100);
				}
			}
		});
    }
	consRealizedPlList:any[] = [];
	getMFPNLStatement = () => {
        this.tabPanelPnlLoader = false;
        this.fromDateReq = this.formatChange(this.fromDate);
        this.toDateReq = this.formatChange(this.toDate);
        this.subscription.add(
            this.clientService.getMFPNLStatement(this.portfolioToken, this.clientCode,this.fromDateReq, this.toDateReq,localStorage.getItem('userId1'), this.clientType)
                .subscribe((res: any) => {
                    if (res['Head']['ErrorCode'] == 0) {
                        if(res['Body']['Table3'] && res['Body']['Table3'].length > 0){
                            var result = this.groupBy1(res['Body']['Table3'], function (item: any) {
                                return [item.Scheme_Name];
                            });
                            this.grandTotalMFpnl=res['Body']['Table3'][res['Body']['Table3'].length-1];
                            this.consRealizedPlList = [];
                            for (var j in result) {
                                if(result[j]["Scheme_Name"] !== ""){        // review
                                var combineObj: any = {
                                    "srNo": parseInt(Object.keys(result)[j]),
                                    "Data": result[j],
                                    "Scheme_Name":result[j][0].Scheme_Name,
                                     "TransactionType":result[j][0].Transaction_Type,
                                    "Transaction_Type": "",
                                    "Transaction_Date": "",
                                    "Purchase_Price": "",
                                    "Units": result[j].reduce((el: any, li: any) => el + parseFloat(li.Units), 0),
                                    "Purchase_Amount": result[j].reduce((el: any, li: any) => el + parseFloat(li.Purchase_Amount), 0),
                                    "Sell_Date": "",
                                    "Sell_Type": "",
                                    "Sell_Rate": "",
                                    "Sell_Amount": result[j].reduce((el: any, li: any) => el + parseFloat(li.Sell_Amount), 0),
                                    "Days": "",
                                    "GL_ST_Debt": result[j].reduce((el: any, li: any) => el + parseFloat(li.GL_ST_Debt), 0),
                                    "GL_ST_Equity": result[j].reduce((el: any, li: any) => el + parseFloat(li.GL_ST_Equity), 0),
                                    "GL_LT_Debt": result[j].reduce((el: any, li: any) => el + parseFloat(li.GL_LT_Debt), 0),
                                    "GL_LT_Equity": result[j].reduce((el: any, li: any) => el + parseFloat(li.GL_LT_Equity), 0),
                                    "STT": "",
                                    "ISIN": "",
                                    "RIA_FLAG": ""
                                };
                                this.consRealizedPlList.push(combineObj);
                            }
                                
                            }
                            this.consRealizedPlList = this.consRealizedPlList.sort((a, b) => (a.ScripName > b.ScripName) ? -1 : 1);
                            
                        }  else{
                            this.consRealizedPlList = [];
                        }
                    }
                    this.tabPanelPnlLoader= true;
                    
                })
        )
    }
	groupBy1(array: any, f: any) {
		let groups: any = {};
		array.forEach(function (o: any) {
			if(o['Scheme_Name'] != ""){
				var group = JSON.stringify(f(o));
				groups[group] = groups[group] || [];
				groups[group].push(o);
			}
		});
		return Object.keys(groups).map(function (group) {
			return groups[group];
		})
	}
	displayRealisePlValue(){
		if(this.unRealisePlValue){
			return this.commonService.numberFormatWithCommaUnit(this.unRealisePlValue);	
		}
		return 0;
		
	}
	
	private GetTotalEquityDividend = (consolidate: string = "0", downloadReport: boolean = false) => {

		let passClientCode = this.pnlClientCode;
		if(consolidate == "1") passClientCode = this.realizedClientCode;
		if(!downloadReport) this.tabPanelPnlLoader = false;
		this.fromDateReq = this.formatChange(this.fromDate, 'MM/DD/YYYY');
        this.toDateReq = this.formatChange(this.toDate, 'MM/DD/YYYY');
        this.subscription.add(
			this.clientService.GetTotalEquityDividend(this.portfolioToken, passClientCode,this.fromDateReq, this.toDateReq,consolidate)
			.subscribe({
				next: (res:any)=> { 

					if(!downloadReport){
						if(res['Head']['ErrorCode'] == 0){
							this.dividendTableList = res['Body'];
						} else{
							this.dividendTableList = { Total_Dividend_Amount: "0", EquityDividend: []};
							this.toast.displayToast(res['Head']['ErrorDescription']);
						}
						this.tabPanelPnlLoader = true;
					} else {
						const reportArray = this.selectedReport.map((item: any) => item.value);
						if(res['Head']['ErrorCode'] == 0){
							let dividendData: any[] = [];
							let reportData = res['Body'];
							let individualTotalData: any = [];
							
							for(let i in reportData.EquityDividend){
								let dividendValue = reportData.EquityDividend[i]['Dividend_Amount'] != "" && reportData.EquityDividend[i]['Dividend_Amount'] != null && reportData.EquityDividend[i]['Dividend_Amount'] != undefined ? parseFloat(reportData.EquityDividend[i]['Dividend_Amount']) : 0.00;

								// all dividend details
								dividendData.push({
									isTotal: false,
									isSumRow: false,
									scripName: reportData.EquityDividend[i].ScripName,
									exDate: reportData.EquityDividend[i].ExDate,
									dividendAmount: dividendValue,
									clientCode: reportData.EquityDividend[i].ClientCode
								});
								// total dividend details
								if(individualTotalData.length > 0){

									let createNew!: boolean;
									let existingIndex = "";
									for(let j in individualTotalData){
										if(individualTotalData[j].clientCode == reportData.EquityDividend[i].ClientCode){
											createNew = false;
											existingIndex = j;
											break;
										} else {
											createNew = true;
										}
									}

									if(createNew){
										individualTotalData.push({
											isTotal: false,
											isSumRow: true,
											scripName: "",
											exDate: "",
											dividendAmount: dividendValue,
											clientCode: reportData.EquityDividend[i].ClientCode
										});
									}
									else {
										individualTotalData[existingIndex].dividendAmount += dividendValue;
									}
								} else {
									individualTotalData.push({
										isTotal: false,
										isSumRow: true,
										scripName: "",
										exDate: "",
										dividendAmount: dividendValue,
										clientCode: reportData.EquityDividend[i].ClientCode
									});
								}
							}

							dividendData.push(...individualTotalData);
							dividendData = [...dividendData.sort((a, b) => (a.exDate < b.exDate) ? -1 : 1)]
							dividendData = [...dividendData.sort((a, b) => (a.dividendAmount > b.dividendAmount) ? -1 : 1)]
							dividendData = [...dividendData.sort((a, b) => (a.clientCode > b.clientCode) ? -1 : 1)]

							dividendData.push({
								isTotal: true,
								isSumRow: false,
								scripName: "",
								exDate: "",
								dividendAmount: reportData.Total_Dividend_Amount,
								clientCode: "",
								INSTRUMENTNAME: "Total"
							});
							this.realizedPlData = [...this.realizedPlData, {
								"pageTitle": "P&L Statement",
								"pageNumber": "5",
								"subTitle": 'Dividend',
								"hasTable": true,
								"tableHead": [
									"Scrip Name",
									"Date",
									"Amount",
								],
								"totalData": dividendData,
							}]
						} else {
							if (reportArray.length === 1 && reportArray.includes('pl')) {
								if (this.realizedPlData.length === 0) {
									this.toast.displayToast('No record found');
									this.pdfLoader = true;
								} else {
								}
							}
						}
					}
				},
				error: (err: any) => {
					this.tabPanelPnlLoader = true;
					this.toast.displayToast("Unable to fetch Dividend Details");
				}
		}));
	}
	apkWebViewclose(){
		
	}
}