<div class="parent-block">
  <!-- <div class="close">
    <img (click)="dissmiss()" src="assets/svg/close.svg" alt="">
  </div> -->
  <div class="text-block">
    <iframe src="https://internaladmin.iifl.in/AAATC/terms_and_conditions.html" frameborder="0"></iframe>
    <!-- <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
    <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> -->
  </div>
  <div class="button-block">
    <ion-button (click)="dissmiss()">I Agree</ion-button>
  </div>
</div>
