import { Component, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
	selector: 'app-analytics',
	templateUrl: './analytics.component.html',
	styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent {
	@ViewChild('iframe') iframe!: ElementRef;
	@Input() displayAnalyticsSection: boolean = false;
	isApkWebView = false;
	holdingData:any[] = []
	constructor(private route: ActivatedRoute, private router: Router) {
		// if(this.displayAnalyticsSection){
			this.route.queryParams.subscribe((params: any) => {
				this.isApkWebView =  params?.['isApkWebView'];
			});
			this.holdingData = localStorage.getItem('totalHoldings') ? JSON.parse(localStorage.getItem('totalHoldings') || "{}") : "[]";
		// }
	}

	loadIframe(){
		if(this.holdingData.length > 0){
			// setTimeout(() => {
				const payload = JSON.stringify({
					source: "IIFL",
					data: {
						holdings: this.holdingData,
					},
				});
				
				this.iframe.nativeElement.contentWindow.postMessage(payload, "*");
	
				// this.iframe.nativeElement.setAttribute('src', "https://newsiifl.trendlyne.com/clientapi/irwin/webview/FHZVJwFXDC/portfolio-report/");
			// }, 1000);
		}
	}
	goBack(){
		localStorage.setItem('fromConnected','true')
    	window.history.back();
	}
	apkWebViewclose(){
		
	}

}
