<div class="modal-content no-internet-modal">
	<div class="header">
		<!-- <div class="close" (click)="dismiss(false)">
			X
		</div> -->
	</div>
	<div class="content">
		<div class="image">
			<img src="assets/imgs/noInternet.jpg" alt="">
		</div>
		<!-- <h3>oops, no connection</h3>
		<p>
			Make sure wifi or cellular data is turned on and then try again
		</p> -->
	</div>
</div>