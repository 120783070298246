import { Component, Inject, ViewChild} from '@angular/core';
import {  Router } from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { WireRequestService } from '../../pages/wire-requests/wire-requests.service';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import {CodeInputComponent} from 'angular-code-input';
import { CustomEncryption } from '../../../config/custom-encrypt';
import { ToasterService } from '../../helpers/toaster.service';
import { ClientTradesService } from '../../pages/recently-viewed-client-list/client-trades.service';
import { StorageServiceAAA } from '../../helpers/aaa-storage.service';
import { CommonService } from '../../helpers/common.service';
import * as CryptoJS from 'crypto-js';
import * as lodash from 'lodash';
@Component({
  selector: 'app-connected-accounts',
  templateUrl: './connected-accounts.component.html',
  styleUrl: './connected-accounts.component.scss',
  providers: [ CommonService, WireRequestService, ClientTradesService]

})
export class ConnectedAccountsComponent {
	hideModel: boolean = false;
	AddmemOverlay: boolean = false;
	ParentClientCode: any;
	MakerId: any;
	selectedClient:any=null;
	TotalValue: any=0;
	externalBrokerList:any = []
	userType: any;
	previousUrl: any;
	clientDetails: any;
	isPartner: any = false;
  constructor(private router: Router,private commonService: CommonService,
		private route: ActivatedRoute,
		private storage: StorageServiceAAA,
		@Inject(DOCUMENT) private document: any,	//Document,
		 private clientService: ClientTradesService,private wireReqService: WireRequestService,
		 private ciphetText: CustomEncryption, public toast: ToasterService) { 
			
		 }
     @ViewChild('codeInput') codeInput !: CodeInputComponent;
	 generatedLink = null;
     displayMemberContent:boolean = true;
     clientId:any;
     otpInput:any;
     memberClientCode:any;
     selectRelation:any;
     tokenValue:any;
     checkOtp:any;
     userIdValue:any;
     dataLoad?:boolean;
     display1:boolean = false;
     display2:boolean = false;
     display3:boolean = false;
     verifyBtn:boolean = true;
     displayTabs:boolean = true;
     jointHolders: any = [];
	 isApkWebView = false;
   
     portfolioToken:any;
     portfolioId:any;
     portfolioUserId:any;
     private subscription: Subscription = new Subscription();
	clientName: any;
	displayClientCode?: string;
	showrefreshlist = null;
	displayRelation = "FAMILY";



  goBack() {
	localStorage.setItem('fromConnected','true')
		if(this.familyMappList.length == 0){
			localStorage.setItem('familyListConnected', this.clientId)

		} else {
			localStorage.setItem('familyListConnected', this.familyMappList[0].ClientCode)

		}
    	window.history.back();
  }
  showRefresh(i:any) {
	this.showrefreshlist === i ? this.showrefreshlist = null : this.showrefreshlist = i
  }
 async generateLink(){
	if((this.userType == 'RM' || this.userType == 'FN'|| this.userType === 'SB') && this.isPartner == 'true'){

		this.cleverTabAndAppflyerEvent('ExternalPortfolio_GenerateLinkClicked')
	} else {
		this.cleverTabAndAppflyerEvent('Cl_ExternalPortfolio_GenerateLinkClicked')
	}
	
		try {
			const signedToken   = await  this.generateEdgeToken()
			const obj=  localStorage.getItem('userId1')
			this.clientService.generateExternalConnectLinkRm(signedToken,obj).subscribe((res:any)=>{
				if(res?.link){
					this.generatedLink = res?.link
					this.hideModel = true
				}
			}, error=>{
				this.errorDisplay(error)
			})

		} catch (error:any){
			this.errorDisplay(error)
		}
	}
	closegeneratelink() {
		this.hideModel = !this.hideModel;
		this.document.querySelector('.tableoverlay').classList.add('d-none');
	}
  onOtpChanged(event: any){
		this.otpInput = event
	}

	onotpFieldCompleted(event: any){
		// const btnEle = document.getElementById('verifyMemberBtn') as HTMLInputElement;
		// btnEle.disabled = false;
		this.verifyBtn = false;
	}

	addMemberForm(){
		this.AddmemOverlay = !this.AddmemOverlay;
		this.display1 = true;
		this.display2 = false;
		this.display3 = false;
		this.otpInput = null;
		this.verifyBtn = true;

	}

	addMemberFormnext(){
		if(this.memberClientCode && this.selectRelation){
			let params = {
				"Type": "Add",
				"FamilyName": this.memberClientCode,
				"MakerID": this.clientId,
				"Relation": this.selectRelation,
				"OTP":  ""
			}
			this.subscription.add(
				this.clientService.getMemberDetails(params,this.portfolioToken)
					.subscribe((res: any) => {
						if(res['Head']['ErrorCode'] == 0){
							if(res['Body']['ClientFamilyMappingDetailsMapp']){
							  let getOtpObj = res['Body']['ClientFamilyMappingDetailsMapp'].filter((obj: any) => obj.ClientCode == this.memberClientCode && obj.Status == 'Inactive');
							   this.checkOtp = getOtpObj[0]['OTP'];
							
							   localStorage.setItem('saveOtp', this.checkOtp);
							   	this.display1 = false;
								this.display2 = true;
								this.display3 = false;
							 //  this.document.querySelector('.step2').classList.remove('d-none');
							//    const ele1 = document.getElementById('clientIdFrom1') as HTMLInputElement;
							//    ele1.style.display = 'none';
							//    const ele2 = document.getElementById('otpBox1') as HTMLInputElement;
							//    ele2.style.display = 'block';
							}
						
						}
						else if (res['Head']['ErrorCode'] == 1 && res['Head']['ErrorDescription'].includes('already exist')) {
							let alredyExistClientObj = res['Body']['ClientFamilyMappingDetailsMapp'].filter((obj: any) => obj.ClientCode == this.memberClientCode && obj.Status == 'Inactive');
							this.resendOTP()
						}
						else{
							this.toast.displayToast(res['Head']['ErrorDescription']);
						}
					})
			)
		}
		else{
			this.toast.displayToast('Please select input value')
		}
	}

	resendOTP(){
		let params = {
			"Type": "Resend",
			"FamilyName": this.memberClientCode,
			"MakerID": this.clientId,
			"Relation": this.selectRelation,
			"OTP":  ""
		}
		this.subscription.add(
			this.clientService.getMemberDetails(params,this.portfolioToken)
				.subscribe((res: any) => {
					if(res['Head']['ErrorCode'] == 0){
						if(res['Body']['ClientFamilyMappingDetailsMapp']){
						  let getOtpObj = res['Body']['ClientFamilyMappingDetailsMapp'].filter((obj: any) => obj.ClientCode == this.memberClientCode && obj.Status == 'Inactive');
						   this.checkOtp = getOtpObj[0]['OTP'];
						
						   localStorage.setItem('saveOtp', this.checkOtp);
						   this.display1 = false;
						   this.display2 = true;
						   this.display3 = false;
						//    this.document.querySelector('.step2').classList.remove('d-none');
						//    const ele1 = document.getElementById('clientIdFrom1') as HTMLInputElement;
						//    ele1.style.display = 'none';
						}
					
					}
					else{
						this.toast.displayToast(res['Head']['ErrorDescription']);
					}
				})
			)	
	}

	closeForm() {
		// this.document.querySelector('.addmemeberForm1').classList.add('d-none');
		// this.document.querySelector('.tableoverlay').classList.add('d-none');
		this.AddmemOverlay = !this.AddmemOverlay;
		this.resetForm();
		this.getFamilyDropdown()
	}

	resetForm(){
		// const ele1 = document.getElementById('clientIdFrom1') as HTMLInputElement;
		// ele1.style.display = 'block';
		// const ele = document.getElementById('otpBox1') as HTMLInputElement;
		// ele.style.display = 'none';
		this.memberClientCode = null;
		this.selectRelation = null;
		this.otpInput = null;
		this.display1 = true;
		this.display2 = false;
		this.display3 = false;
		this.verifyBtn = true;
		const ele1 = document.getElementById('memberPop') as HTMLInputElement;
		ele1.style.display = 'block';

	}
  ngOnInit() {
		localStorage.setItem('saveClientId', 'true');
		this.subscription = new Subscription();
		this.route.queryParams.subscribe((params:any) => {
			this.clientId =	params?.['code'];
			this.isPartner = params?.['displayClientDropDownField']
			this.isApkWebView = params?.['isApkWebView']
			this.storage.get('userType').then(type => {
			if (type === 'RM') {
				this.userType = type;
			} else if (type === 'FAN') {
				this.userType = 'FN';
			} else {
				this.userType = 'SB';
			}
		})
		if(this.isPartner == 'true'){
			this.storage.get('userType').then(type => {
				if (type === 'RM' || type === 'FAN') {
					this.storage.get('userID').then((userId) => {
						this.storage.get('bToken').then(token => {
							this.portfolioToken = token;
							this.portfolioUserId = this.ciphetText.aesEncrypt(userId);
						
						})
					})
				
				} else {
					this.storage.get('subToken').then(token => {
						this.storage.get('userID').then((userId) => {
							this.portfolioToken = token;
							this.portfolioUserId = this.ciphetText.aesEncrypt(userId);
						})
						
					})
				}
	
			})
		} else {
			this.portfolioToken = '.ASPXAUTH='+localStorage.getItem('portfolioToken');
			this.portfolioUserId = ""
			this.portfolioId = localStorage.getItem('portfolioId');
			if(!(this.portfolioToken && this.portfolioId)){
				this.router.navigate(['/not-found']);
				return;
			}
		}

		setTimeout(() => {
			this.getFamilyDropdown();
		}, 1500);

		
		
		localStorage.removeItem('saveOtp');
		localStorage.removeItem('memberCount');
		});
		
		
	}
  profileDetails:any = [];
	clientMobNo:any;
	clientEmail:any;
	activeSegment:any;
	familyMappList:any=[];
	
	getFamilyDropdown() {
		let clientID = this.ciphetText.aesEncrypt(this.clientId);	
		this.subscription.add(
			this.clientService.getFamilyMapping(this.portfolioToken, clientID, this.portfolioUserId)
				.subscribe((res: any) => {
					if(res['Head']['ErrorCode'] == 0){
						this.dataLoad = false;
						if(res['Body']['FamillyMapp'].length > 0){
							
							if(res['Body']['FamillyMapp'][0]['Successflag'] == 'N'){
								this.familyMappList = [];
							}
							else{
								this.familyMappList = res['Body']['FamillyMapp'];
								this.familyMappList.forEach((element:any) => {
									element.Relation = element.Relation && element.Relation.length >  0 ? element.Relation : 'SELF';
								})
								 const selectedData = this.familyMappList.filter((temp:any)=> temp.ClientCode.toLowerCase() == this.clientId.toLowerCase())
								 this.selectedClient = selectedData[0];
								 this.fetchExternalHoildingSummary();
								 const clientCodeList:any = []
								 this.familyMappList.forEach((user:any)=> clientCodeList.push(user.ClientCode))
								 this.checkConsentAndData(clientCodeList)
							}
							
						}
						else{
							this.familyMappList = [];
						}
						
					}
				}, (error: any) => {
					this.familyMappList = [];
					this.dataLoad = false;
				})
		)
	}

	

	verifyMember(){
		if(this.otpInput == this.checkOtp){
			let params = {
				"Type": "Verify",
				"FamilyName": this.memberClientCode,
				"MakerID": this.clientId,
				"Relation": this.selectRelation,
				"OTP": this.checkOtp
			}
			this.subscription.add(
				this.clientService.getMemberDetails(params,this.portfolioToken)
					.subscribe((res: any) => {
						if(res['Head']['ErrorCode'] == 0){
							this.toast.displayToast(res['Body']['SuccessMsg'])
							const ele1 = document.getElementById('memberPop') as HTMLInputElement;
							ele1.style.display = 'none';
							this.display1 = false;
							this.display2 = false;
							this.display3 = true;
							// this.document.querySelector('.step3').classList.remove('d-none');
							// const ele1 = document.getElementById('memberPop') as HTMLInputElement;
							// ele1.style.display = 'none';
						}
						else{
							this.toast.displayToast(res['Body']['ErrorDescription'])
						}
					})
			)
			//this.addVerifyMember('Verify', this.checkOtp);
		
		}
		else{
			this.toast.displayToast('Invalid OTP');
		}
		
	}

	removeMember(data: any){
		let params = {
			"Type": "Delete",
			"FamilyName": data.ClientCode,
			"MakerID": this.clientId,
			"Relation": data.Relation,
			"OTP": ""
		}
		this.subscription.add(
			this.clientService.getMemberDetails(params,this.portfolioToken)
				.subscribe((res: any) => {
					if(res['Head']['ErrorCode'] == 0){
						this.toast.displayToast(res['Body']['SuccessMsg'])
						this.getFamilyDropdown();
						
					}
					else{
						this.toast.displayToast(res['Body']['ErrorDescription'])
					}
				})
		)
	}

	async generateEdgeToken (){
		try {
			const res = await firstValueFrom( this.clientService.investEdgeLink())
			if(res.local_time){
				const localTime = new Date(res.local_time);

				let Header = {
					"alg": "HS256",
					"typ": "JWT"
				}
				let payload = {
					"iss": "iiflsecurities",
					"iat": Math.floor(localTime.getTime() / 1000.0),
					"exp": Math.floor(localTime.getTime() / 1000.0) + 300,
					"aud": "Beyondirr"
				}

				let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(Header));
				let encodedHeader = this.commonService.base64url(stringifiedHeader);

				let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
				let encodedData = this.commonService.base64url(stringifiedData);

				let token = encodedHeader + "." + encodedData;

				let signature: any = CryptoJS.HmacSHA256(token, 'xYdKntheEOrsOizx0NCAwRuJG70AK3pZTKIY3O1PWyp4J09OxhfvjcvpLtpnnUDm');
				signature = this.commonService.base64url(signature);

				let signedToken = token + "." + signature;
				return signedToken
			} else {
				return null
			}
		} catch (error){
			return null
		}
	}

	copyMessage(val: any){
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.toast.displayToast('Copied');
	  }

	getShortName(value: any){
		if(value && value.length){
			var shortName = value.match(/\b(\w)/g); // ['J','S','O','N']
			return shortName.join('');
		}

	}

	async checkConsentAndData(clientCode: any) {
		let count = 0;
		try {
	  
		  // Use a for...of loop instead of forEach
		  for (const code of clientCode) {
			const signedToken = await this.generateEdgeToken();
			
			// Await the subscription, assuming it's an observable
			const res = await new Promise<any>((resolve, reject) => {
			  this.clientService.getConsent(signedToken, code.toUpperCase()).subscribe({
				next: (response) => resolve(response),
				error: (err) => reject(err)
			  });
			});
			// Update the consent data for each client in the correct position
			const consentData = {
			  "has_mf_consent": res?.has_mf_consent || false,
			  "has_mf_portfolio": res?.has_mf_portfolio || false,
			  "has_stock_consent": res?.has_stock_consent || false,
			  "has_stock_portfolio": res?.has_stock_portfolio || false
			};
	  
			if (this.familyMappList[count].Relation === 'SELF') {
			  this.selectedClient.consentData = consentData;
			} else {
			  this.familyMappList[count].consentData = consentData;
			}
	  
			count++;
		  }
		  
		} catch (error: any) {
			const consentData = {
				"has_mf_consent": false,
				"has_mf_portfolio":  false,
				"has_stock_consent":  false,
				"has_stock_portfolio":  false
			  };
			if (this.familyMappList[count].Relation === 'SELF') {
				this.selectedClient.consentData = consentData;
			  } else {
				this.familyMappList[count].consentData = consentData;
			  }
		
			  count++;
		}
	  }

	async refreshExternalStockData(ClientCode:any,Pan:any,PartnerCode:any){
		if((this.userType == 'RM' || this.userType == 'FN'|| this.userType === 'SB') && this.isPartner == 'true'){

			this.cleverTabAndAppflyerEvent('ExternalPortfolio_RefreshstocksClicked')
		} else {
			this.cleverTabAndAppflyerEvent('Cl_ExternalPortfolio_RefreshstocksClicked')
		}
		try {

			const signedToken   = await  this.generateEdgeToken()
			const obj= {
				client: ClientCode,
				pan_no: Pan,
				manager: PartnerCode
			}
			this.clientService.refreshExternalStockData(signedToken,obj).subscribe((res:any)=>{
				if(res?.link){
					this.toast.displayToast(res?.message)
				}
			}, error=>{
				this.errorDisplay(error)
			})

		} catch (error:any){
			this.errorDisplay(error)
		}
	}

	async getClientProfileDetails(clientCode:any) {
		var obj = { "UserCode": clientCode, "UserType": "4", "ClientType": '' }
		this.wireReqService.getProfileDetails(this.portfolioToken, obj).subscribe((res: any) => {
			if (res['Head']['ErrorCode'] == 0) {
				this.clientDetails = res['Body'];
			}
		}, error => {
		})
	}
	

	
	async generateExternalConnectLink(ClientCode:any,name:any){
				if((this.userType == 'RM' || this.userType == 'FN'|| this.userType === 'SB') && this.isPartner == 'true'){

			if(name){
				this.cleverTabAndAppflyerEvent('ExternalPortfolio_RefreshMFClicked')
			} else {
				this.cleverTabAndAppflyerEvent('ExternalPortfolio_Initiated')
			}
		} else {
			if(name){
				this.cleverTabAndAppflyerEvent('Cl_ExternalPortfolio_RefreshMFClicked')

			} else {
				this.cleverTabAndAppflyerEvent('Cl_ExternalPortfolio_Initiated')

			}
		}
		try {
			// await this.getClientProfileDetails(ClientCode)
			const data:any = await this.setSelectedClient(ClientCode)
			const signedToken   = await  this.generateEdgeToken()
			const obj= {
				client: data?.ClientCode ,
				pan_no: data?.ClientPanno ,
				email:  data?.ClientEmail ,
				 manager : this.userType == 'SB' ? this.clientDetails?.RMCode:localStorage.getItem('userId1'),
				 mode : this.isPartner == 'true' ? 'AAA':'360'
			}
			this.clientService.generateExternalConnectLink(signedToken,obj).subscribe((res:any)=>{
				if(res?.link){
					const anchor = document.createElement('a');
					anchor.href = res?.link;
					anchor.click();
				}
			}, error=>{
				const keys = Object.keys(error?.error?.error?.details)
				let item:any = []
				for(let key of keys){
					if(key == 'pan_no'){
						if(typeof error?.error?.error?.details[key][0] === 'string') {
						item.push({key: key,value:error?.error?.error?.details[key]})

						} else {
						item.push({key: key,value:error?.error?.error?.details[key][0]})

						}

					} else {
						item.push({key: key,value:error?.error?.error?.details[key]})
					}
				}
				let message = ''
				for(let msg of item){
					message = message+msg.key.replace(/[^a-zA-Z ]/g, " ").toUpperCase()+': '+msg.value+"\n" 
				}
				this.toast.displayToast(message.replace(/\n/g, '\n'))
			})

		} catch (error:any){
			this.errorDisplay(error)
		}
	}

	async setSelectedClient(getClientCode:any) : Promise<any>{
		var obj = { 
			"UserCode": getClientCode, "UserType": "4", "ClientType": "" 
		}
		return new Promise((resolve, reject) => {
			this.wireReqService.getProfileDetails(this.portfolioToken, obj).subscribe(
				(res: any) => {
					if (res['Head']['ErrorCode'] === 0) {
						resolve(res['Body']);  // Resolve the Promise with the response body
					} else {
						reject('Error in response');  // Reject if there's an error code
					}
				},
				(error) => {
					reject(error);  // Reject the Promise in case of an error
				}
			);
		});
	}

	async 	fetchExternalHoildingSummary(){
		try {
			const signedToken   = await  this.generateEdgeToken()
			const obj=  this.selectedClient?.ClientCode.toUpperCase()
			for(let i =0; i < this.familyMappList.length; i++){
				this.clientService.fetchExternalHoildingandSummary(signedToken,this.familyMappList[i].ClientCode.toUpperCase(),true).subscribe((res:any)=>{
					if(res){
						const data:any = res;
						this.chartDisplay2External(data,i)
					}
	
				}, (error:any)=>{
					// this.errorDisplay(error)
				})
			}
			

		} catch (error:any){
			// this.errorDisplay(error)
		}
		
		
	}

	chartDisplay2External(array:any,i:any) {
		this.familyMappList[i].TotalValue = array.reduce((accumulator:any, current:any) => parseInt(accumulator) + parseInt(current.market_value), 0)
		let groupedKeys = lodash.groupBy(array, (item) => item.broker_name !== null ? item.broker_name.toLowerCase() : 'Direct');
		const keys = Object.keys(groupedKeys)
		this.familyMappList[i].externalBrokerList =[]
		for(let item of keys) {
			let data = 0
			data = groupedKeys[item].reduce((accumulator:any, current:any) => parseInt(accumulator) + parseInt(current.market_value), 0)
			
			this.familyMappList[i].externalBrokerList.push({name:item,total:data})
		}
	}

	cleverTabAndAppflyerEvent(name:any){
		if((this.userType == 'RM' || this.userType == 'FN'|| this.userType === 'SB') && this.isPartner == 'true'){
			this.commonService.setClevertapEvent(name, { 'Login ID': localStorage.getItem('userId1') });
			this.commonService.triggerAppsflyerLogEvent(name, { 'Login ID': localStorage.getItem('userId1') });
		} else {
			
			this.commonService.setClevertapEvent(name, { 'Login ID': localStorage.getItem('userId1') });
			this.commonService.triggerAppsflyerLogEvent(name, { 'Login ID': localStorage.getItem('userId1') });
		}
	  }

	  errorDisplay(error:any){
		const keys = Object.keys(error?.error?.error?.details)
		let item:any = []
		for(let key of keys){
			if(key == 'pan_no'){
				if(typeof error?.error?.error?.details[key][0] === 'string') {
				item.push({key: key,value:error?.error?.error?.details[key]})

				} else {
				item.push({key: key,value:error?.error?.error?.details[key][0]})

				}

			} else {
				item.push({key: key,value:error?.error?.error?.details[key]})
			}
		}
		let message = ''
		for(let msg of item){
			message = message+msg.key.replace(/[^a-zA-Z ]/g, " ").toUpperCase()+': '+msg.value+"\n" 
		}
		this.toast.displayToast(message.replace(/\n/g, '\n'))
	  }

	  apkWebViewclose(){
		
	  }

}
