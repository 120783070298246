import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GuestDashBoardService } from '../../pages/dashboard/guest-dashboard.service';
import { CommonService } from '../../../helpers/common.service';

@Component({
	selector: 'app-guest-onboarding-modal',
	providers: [GuestDashBoardService],
	templateUrl: './guest-onboarding-modal.component.html',
	styleUrl: './guest-onboarding-modal.component.scss'
})
export class GuestOnboardingModalComponent {
	progressVal: any = 0;
	constructor(private modalController: ModalController,
		private serviceFile: GuestDashBoardService,
		public commonService: CommonService) { this.getOnboardingProgress(); }

	dismiss() {
		this.modalController.dismiss();
	}

	getOnboardingProgress() {
		this.serviceFile.getUserOnboardData(localStorage.getItem("GuestMobileNumber"))
			.subscribe((res: any) => {
				if (res && res['ArrayOfResponse'] && res['ArrayOfResponse'].length > 0) {
					this.progressVal = res['ArrayOfResponse'][0]['UserOnBoardPrecentage'] / 100;
				} else if (res && res['Message'] === "User Doesn't Exists") {
					this.progressVal = 0;
				}
			});
	}

	onResumeOnboarding() {
		this.commonService.redirectToOptimum();
	}
}
