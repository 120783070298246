<div class="card_details_pop">
    <!-- <div class="modal_title">
         <span>Become A Partner</span>
         <div class="pop_close" (click)="dismiss()"><img src="assets/svg/close_black.svg" width="18" alt="close"></div>
     </div> -->
     <div class="quick-madal-inner">
        <div>
            <h4>'Stay tuned! Detailed information will be available soon'</h4>
            <ion-button (click)="dismiss()" class="w-150 become-ptr-btn">OK</ion-button>
        </div>
     </div>
 </div>